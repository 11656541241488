import React, { useState } from "react";

const FAQ = () => {
  const [expanded, setExpanded] = useState(null);
  const faqData = [
    {
      id: 1,
      question: "What is a multisig crypto wallet?",
      answer:
        "A multisig (multi-signature) crypto wallet is a digital wallet that requires multiple private keys to authorize a transaction. This enhances security by ensuring that more than one person or device must approve a transaction before it is executed. For instance, in a 2-of-3 multisig wallet, two out of three designated private keys must approve a transaction.",
    },
    {
      id: 2,
      question: "How does a multisig wallet enhance security?",
      answer:
        "A multisig wallet enhances security by requiring multiple approvals before a transaction can proceed. This reduces the risk of theft, as one compromised key is not enough to authorize a transaction.",
    },
    {
      id: 3,
      question:
        "What does it mean that the wallet stores secret keys on the device's keychain?",
      answer:
        "Storing secret keys on the device's keychain provides an additional layer of security by keeping keys secure and accessible only to the wallet application.",
    },
    {
      id: 4,
      question: "What are the different configurations of a multisig wallet?",
      answer:
        "Multisig wallets can be configured in various ways, such as 2-of-3, 3-of-5, etc., meaning different numbers of keys are needed for transaction approval.",
    },
    {
      id: 5,
      question:
        "How does the keychain storage enhance security in a multisig wallet?",
      answer:
        "Keychain storage enhances security by securing keys on the device itself, making them harder to access unauthorizedly.",
    },
    {
      id: 6,
      question:
        "What happens if one of the keys in a multisig wallet is lost, especially with keychain storage?",
      answer:
        "If a key is lost, the wallet's multisig configuration may allow transactions with the remaining keys, depending on its setup.",
    },
    {
      id: 7,
      question: "Are multisig wallets compatible with all cryptocurrencies?",
      answer:
        "Not all cryptocurrencies support multisig wallets; compatibility depends on the blockchain technology and wallet provider.",
    },
    {
      id: 8,
      question:
        "How do transaction approvals work in a multisig wallet with keychain-stored keys?",
      answer:
        "In a multisig wallet with keychain-stored keys, each transaction requires approval from a set number of keys, which are securely stored in the device's keychain.",
    },
  ];

  const toggleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <section className="w-full px-4 py-24 relative overflow-hidden">
      {/* Background gradient circles */}
      <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
      <div className="absolute top-0 right-1/4 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000"></div>
      <div className="absolute -bottom-8 left-1/3 w-96 h-96 bg-pink-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-4000"></div>

      <div className="max-w-4xl mx-auto text-white relative">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Frequently Asked Questions
          </h2>
          <p className="mt-4 text-gray-400">Everything you need to know about TrustVault</p>
        </div>

        <div className="space-y-4">
          {faqData.map((item) => (
            <div
              key={item.id}
              className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 transition-all duration-300 hover:border-white/20"
            >
              <button
                className="w-full px-8 py-6 text-left flex justify-between items-center group"
                onClick={() => toggleExpand(item.id)}
              >
                <span className="text-lg font-medium pr-8 group-hover:text-lime-400 transition-colors">
                  {item.question}
                </span>
                <span className={`transform transition-transform duration-300 text-lime-400 ${
                  expanded === item.id ? 'rotate-45' : ''
                }`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </span>
              </button>
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  expanded === item.id ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <div className="px-8 pb-6 text-gray-300 leading-relaxed">
                  {item.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
