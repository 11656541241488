import React from "react";
import Footer from "./Footer";
import { FaShieldAlt, FaUserLock, FaDatabase, FaShareAlt, FaGlobe, FaEnvelope } from "react-icons/fa";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-t from-neutral-950 to-zinc-900">
      {/* Hero Section */}
      <section className="w-full px-4 pt-16 pb-12 relative overflow-hidden">
        {/* Background gradient circles */}
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 right-1/4 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000"></div>
        
        <div className="max-w-4xl mx-auto text-white relative">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">
              Privacy <span className="text-lime-400">Policy</span>
            </h1>
            <p className="text-lg text-gray-300">
              Last Updated: December 12, 2024
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full px-4 py-8">
        <div className="max-w-4xl mx-auto space-y-8">
          {/* Company Info Card */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-4">
              <FaShieldAlt className="w-6 h-6 text-lime-400" />
              <div>
                <h2 className="text-xl font-bold">Trust Vault LLC</h2>
                <p className="text-gray-300">Incorporated in Wyoming, United States</p>
              </div>
            </div>
            <p className="text-gray-300 leading-relaxed">
              Trust Vault LLC operates the Trust Vault application ("App"), providing you with a self-custody crypto wallet
              to securely manage your crypto assets and purchase assets via third-party decentralized exchanges ("DEXs") within the App.
              This Privacy Policy explains how we collect, use, disclose, and protect your information.
            </p>
          </div>

          {/* Information Collection */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaUserLock className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">Information We Collect</h2>
            </div>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-semibold text-lime-400 mb-3">Information You Provide</h3>
                <p className="text-gray-300 leading-relaxed">
                  To enhance security, we may collect limited information like your email address or other optional account credentials.
                  Note that Trust Vault is designed as a self-custody wallet, so your private keys and transaction data are fully owned
                  and managed by you, not by us.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-lime-400 mb-3">Automatically Collected Information</h3>
                <ul className="list-disc list-inside text-gray-300 space-y-2">
                  <li>Device Information: Device type, operating system, and app version</li>
                  <li>Usage Data: App interaction patterns and feature usage</li>
                  <li>Log Data: Diagnostic and error information</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Data Usage */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaDatabase className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">How We Use Your Information</h2>
            </div>
            <ul className="space-y-4 text-gray-300">
              <li className="flex items-start gap-3">
                <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-black font-bold text-xs">1</span>
                </div>
                <p>Provide and improve the App functionality and user experience</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-black font-bold text-xs">2</span>
                </div>
                <p>Offer customer support and troubleshooting assistance</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-black font-bold text-xs">3</span>
                </div>
                <p>Monitor and prevent unauthorized activities</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-black font-bold text-xs">4</span>
                </div>
                <p>Comply with legal requirements and regulations</p>
              </li>
            </ul>
          </div>

          {/* Data Security */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaShareAlt className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">Data Security</h2>
            </div>
            <p className="text-gray-300 leading-relaxed">
              We employ industry-standard security measures to protect your data, including:
            </p>
            <ul className="mt-4 space-y-2 text-gray-300">
              <li className="flex items-center gap-2">
                <div className="w-2 h-2 bg-lime-400 rounded-full"></div>
                <span>Administrative safeguards</span>
              </li>
              <li className="flex items-center gap-2">
                <div className="w-2 h-2 bg-lime-400 rounded-full"></div>
                <span>Technical protection measures</span>
              </li>
              <li className="flex items-center gap-2">
                <div className="w-2 h-2 bg-lime-400 rounded-full"></div>
                <span>Physical security controls</span>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaEnvelope className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">Contact Us</h2>
            </div>
            <p className="text-gray-300 mb-4">
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <p className="text-lime-400">privacy@trustvault.app</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
