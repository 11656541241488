import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import ScrollToTop from "./components/ScrollToTop";
import SafeSend from "./SafeSend";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import ContactForm from "./ContactForm";
import About from "./About";
import Features from "./Features";
import Learn from "./Learn";
import Blockchain101 from "./Blockchain101";
import KnowledgeBase from "./KnowledgeBase";
import FAQPage from "./FAQPage";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/features" element={<Features />}></Route>
        <Route path="/learn" element={<Learn />}></Route>
        <Route path="/safesend" element={<SafeSend />}></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms" element={<TermsAndConditions />}></Route>
        <Route path="/contact" element={<ContactForm />}></Route>
        <Route path="/blockchain101" element={<Blockchain101 />}></Route>
        <Route path="/knowledge-base" element={<KnowledgeBase />}></Route>
        <Route path="/faq" element={<FAQPage />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
