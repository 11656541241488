import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      toast.error('Please complete the CAPTCHA', {
        duration: 5000,
        style: {
          background: '#333',
          color: '#fff',
          padding: '16px',
          fontSize: '1.2rem',
          minWidth: '300px',
        },
      });
      return;
    }
    // Here you would typically send the form data to your backend
    toast.success('Message sent successfully!', {
      duration: 5000,
      style: {
        background: '#333',
        color: '#fff',
        padding: '16px',
        fontSize: '1.2rem',
        minWidth: '300px',
      },
    });
    setFormData({ name: '', email: '', subject: '', message: '' });
    setCaptchaValue(null);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <div className="min-h-screen bg-gradient-to-t from-neutral-950 to-zinc-900 py-12 px-4 sm:px-6 lg:px-8">
      <Toaster 
        position="top-center" 
        containerStyle={{
          top: 100,
        }}
      />
      <div className="max-w-2xl mx-auto bg-zinc-900/50 p-8 rounded-3xl border border-gray-800 backdrop-blur-sm">
        <h2 className="text-4xl font-bold text-white mb-8 text-center">Get in Touch</h2>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-300">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              required
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full rounded-xl bg-zinc-800 border-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3"
              placeholder="Your name"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full rounded-xl bg-zinc-800 border-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3"
              placeholder="your@email.com"
            />
          </div>

          <div>
            <label htmlFor="subject" className="block text-sm font-medium text-gray-300">
              Subject
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              required
              value={formData.subject}
              onChange={handleChange}
              className="mt-1 block w-full rounded-xl bg-zinc-800 border-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3"
              placeholder="Subject of your message"
            />
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-300">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              required
              value={formData.message}
              onChange={handleChange}
              rows={4}
              className="mt-1 block w-full rounded-xl bg-zinc-800 border-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-3"
              placeholder="Your message..."
            />
          </div>

          <div className="flex justify-center">
            <ReCAPTCHA
              sitekey="6LfKFJkqAAAAAMSLXOuj_b7MEhqvdQ68f6T-bY24" // Replace with your actual site key
              onChange={handleCaptchaChange}
              theme="dark"
            />
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
