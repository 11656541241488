import React, { useState } from "react";
import whychoose1 from "./images/why-choose1.svg";
import benefit1 from "./images/benefit1.svg";
import benefit2 from "./images/benefit2.svg";
import feature3 from "./images/feature3.svg";
import zigBg from "./images/zig-bg.svg";
import linearLine from "./images/linear-line.svg";
import crypto1 from "./images/crypto1.svg";
import crypto2 from "./images/crypto2.svg";
import crypto3 from "./images/crypto3.svg";
import buttonBlur from "./images/buttonBlur.svg";
import Footer from "./Footer";
import toast, { Toaster } from 'react-hot-toast';

const Learn = () => {
  const [expandedFaq, setExpandedFaq] = useState(null);

  const handleGetStartedClick = () => {
    toast.success('Mobile App launching soon!', {
      duration: 5000,
      style: {
        background: '#333',
        color: '#fff',
        padding: '16px',
        fontSize: '1.2rem',
        minWidth: '300px',
      },
    });
  };

  const faqs = [
    {
      question: "What is a self-custody wallet?",
      answer: "A self-custody wallet gives you complete control over your digital assets. Unlike custodial wallets where a third party holds your assets, with Trust Vault, you have full ownership and control of your private keys."
    },
    {
      question: "How does multi-signature work?",
      answer: "Multi-signature requires multiple parties to approve a transaction. For example, in a 2-of-3 setup, any two out of three designated signers must approve before a transaction can be executed."
    },
    {
      question: "What is Safe Send?",
      answer: "Safe Send is our innovative feature that helps prevent transaction errors. It initiates a small test transaction that must be verified by the recipient before the full amount is sent, ensuring your funds reach the right destination."
    },
    {
      question: "How secure is Trust Vault?",
      answer: "Trust Vault employs industry-leading security measures including encryption, multi-signature technology, and Safe Send verification. We never have access to your private keys - they remain solely in your control."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-t from-neutral-950 to-zinc-900">
      <Toaster 
        position="top-center" 
        containerStyle={{
          top: 100,
        }}
      />
      
      {/* Hero Section */}
      <section className="w-full px-4 pt-20 pb-16">
        <div className="max-w-6xl mx-auto text-white">
          <div className="text-center mb-16">
            <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold mb-6">
              Learn About <span className="text-lime-400">Trust Vault</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Take control of your digital assets with Trust Vault, a self-custody wallet designed for security, simplicity, and transparency.
            </p>
          </div>
        </div>
      </section>

      {/* What is Trust Vault Section */}
      <section className="w-full px-4 py-16 relative overflow-hidden">
        <img
          src={zigBg}
          alt="Background Pattern"
          className="absolute top-0 right-0 opacity-10 w-full h-full object-cover"
        />
        <div className="max-w-6xl mx-auto text-white relative">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-6">What is Trust Vault?</h2>
              <img src={linearLine} alt="Divider" className="mb-6 w-24" />
              <p className="text-gray-300 text-lg leading-relaxed">
                Trust Vault is a cutting-edge wallet solution that offers both single-signature and multi-signature options. 
                Our platform provides a secure, intuitive, and user-friendly experience for managing your cryptocurrencies.
              </p>
            </div>
            <div className="flex justify-center">
              <img
                src={whychoose1}
                alt="Security Illustration"
                className="w-4/5 max-w-md"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="w-full px-4 py-16 bg-zinc-900/50">
        <div className="max-w-6xl mx-auto text-white">
          <h2 className="text-4xl font-bold text-center mb-16">Key Features</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800">
              <img src={benefit1} alt="Self Custody" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">Self-Custody</h3>
              <p className="text-gray-300">
                Take full control of your digital assets and manage them securely.
              </p>
            </div>

            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800">
              <img src={benefit2} alt="Flexible Options" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">Flexible Wallet Options</h3>
              <p className="text-gray-300">
                Choose from single-signature and multi-signature wallets to suit your needs.
              </p>
            </div>

            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800">
              <img src={crypto2} alt="Safe Send" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">Safe Send</h3>
              <p className="text-gray-300">
                Verify transaction details and prevent losses with our innovative Safe Send feature.
              </p>
            </div>

            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800">
              <img src={crypto3} alt="DEX Access" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">DEX Access</h3>
              <p className="text-gray-300">
                Seamlessly access decentralized exchanges and trade your digital assets.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How it Works Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-6xl mx-auto text-white">
          <h2 className="text-4xl font-bold text-center mb-16">How it Works</h2>
          <div className="grid md:grid-cols-1 lg:grid-cols-5 gap-8">
            {[
              {
                icon: crypto1,
                title: "Create Your Wallet",
                description: "Download the Trust Vault app and create your self-custody wallet."
              },
              {
                icon: feature3,
                title: "Choose Your Wallet Type",
                description: "Select either a single-signature or multi-signature wallet."
              },
              {
                icon: benefit2,
                title: "Set Up Multi-Signature",
                description: "Add multiple signatures to your wallet for extra security."
              },
              {
                icon: crypto2,
                title: "Send and Receive Assets",
                description: "Use Safe Send to verify transactions and prevent losses."
              },
              {
                icon: crypto3,
                title: "Access DEX",
                description: "Trade your digital assets on decentralized exchanges."
              }
            ].map((step, index) => (
              <div key={index} className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800">
                <div className="flex items-center mb-6">
                  <div className="w-8 h-8 rounded-full bg-lime-400 flex items-center justify-center mr-4 text-black font-bold">
                    {index + 1}
                  </div>
                  <img src={step.icon} alt={step.title} className="w-12 h-12" />
                </div>
                <h3 className="text-xl font-bold mb-4">{step.title}</h3>
                <p className="text-gray-300">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Security Section */}
      <section className="w-full px-4 py-16 bg-zinc-900/50">
        <div className="max-w-6xl mx-auto text-white text-center">
          <h2 className="text-4xl font-bold mb-6">Security and Transparency</h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
            At Trust Vault, we prioritize security, transparency, and user trust. Our platform is designed 
            to provide a secure and user-friendly experience.
          </p>
          <div className="relative">
            <img src={buttonBlur} className="absolute -bottom-5 left-1/2 transform -translate-x-1/2" alt="Button Blur" />
            <button 
              onClick={handleGetStartedClick}
              className="relative z-10 bg-gradient-to-t from-neutral-950 to-gray-800 border-2 border-indigo-500 text-white py-4 px-8 rounded-full hover:bg-blue-600 transition text-lg font-bold"
            >
              Get Started
            </button>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="w-full px-4 py-24 relative overflow-hidden">
        {/* Background gradient circles */}
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 right-1/4 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-1/3 w-96 h-96 bg-pink-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-4000"></div>

        <div className="max-w-4xl mx-auto text-white relative">
          <div className="text-center mb-16">
            <h2 className="text-5xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              Frequently Asked Questions
            </h2>
            <p className="mt-4 text-gray-400">Everything you need to know about TrustVault</p>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 transition-all duration-300 hover:border-white/20"
              >
                <button
                  className="w-full px-8 py-6 text-left flex justify-between items-center group"
                  onClick={() => setExpandedFaq(expandedFaq === index ? null : index)}
                >
                  <span className="text-lg font-medium pr-8 group-hover:text-lime-400 transition-colors">
                    {faq.question}
                  </span>
                  <span className={`transform transition-transform duration-300 text-lime-400 ${
                    expandedFaq === index ? 'rotate-45' : ''
                  }`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ${
                    expandedFaq === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="px-8 pb-6 text-gray-300 leading-relaxed">
                    {faq.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Learn;
