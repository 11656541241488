import React, { useState } from "react";
import Footer from "./Footer";
import { 
  FaWallet, 
  FaUserShield, 
  FaExchangeAlt, 
  FaTools,
  FaLock,
  FaCogs,
  FaEnvelope,
  FaCheckCircle
} from "react-icons/fa";
import { 
  BiSolidWallet,
  BiSupport,
  BiChevronDown,
  BiPlus
} from "react-icons/bi";

const KnowledgeBase = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const sections = [
    {
      id: 1,
      title: "Getting Started",
      icon: <FaWallet className="w-5 h-5 text-lime-400" />,
      content: [
        {
          subtitle: "What is Trust Vault?",
          text: "Trust Vault is a self-custody crypto wallet that gives you full control over your digital assets. Unlike traditional wallets managed by centralized entities, Trust Vault ensures that only you have access to your private keys, safeguarding your funds.",
          features: [
            "Multi-signature wallet options for enhanced security",
            "Support for multiple blockchains and tokens",
            "Unique 'Safe Send' feature for secure transactions"
          ]
        },
        {
          subtitle: "How to Set Up Your Wallet",
          steps: [
            "Download the App: Visit www.trustvault.app to download the Trust Vault app on iOS or Android",
            "Choose Wallet Type: Select between Single-Signature or Multi-Signature Wallet",
            "Backup Your Seed Phrase: Store your 12- or 24-word seed phrase securely",
            "Start Using Trust Vault: Send, receive, and manage your cryptocurrency"
          ]
        },
        {
          subtitle: "Supported Blockchains",
          items: [
            "Bitcoin (BTC)",
            "Ethereum (ETH)",
            "TON (The Open Network)",
            "Binance Smart Chain (BSC)",
            "Polygon (MATIC)",
            "Solana (SOL)"
          ]
        }
      ]
    },
    {
      id: 2,
      title: "Account Management",
      icon: <FaUserShield className="w-5 h-5 text-lime-400" />,
      content: [
        {
          subtitle: "How to Recover Your Wallet",
          steps: [
            "Open the Trust Vault app",
            "Select 'Recover Wallet' on the login screen",
            "Enter your 12- or 24-word seed phrase exactly as generated during setup",
            "Your wallet will be restored with all funds and transaction history intact"
          ]
        },
        {
          subtitle: "Enabling Multi-Signature Security",
          steps: [
            "Navigate to Settings in the app",
            "Select Upgrade to Multi-Signature Wallet",
            "Add additional trusted devices or accounts",
            "Set up approval requirements for transactions"
          ]
        }
      ]
    },
    {
      id: 3,
      title: "Using Trust Vault",
      icon: <FaExchangeAlt className="w-5 h-5 text-lime-400" />,
      content: [
        {
          subtitle: "Sending Cryptocurrency",
          steps: [
            "Open the app and select the cryptocurrency you want to send",
            "Enter the recipient's wallet address and the amount",
            "Confirm the transaction details and approve",
            "Submit the transaction and track its progress"
          ]
        },
        {
          subtitle: "Receiving Cryptocurrency",
          steps: [
            "Select the cryptocurrency you wish to receive",
            "Copy your wallet address or scan the QR code",
            "Share your address with the sender",
            "Wait for the transaction to be confirmed"
          ]
        },
        {
          subtitle: "Safe Send Feature",
          steps: [
            "Platform sends a $1 test transaction",
            "Recipient requests 2-factor authentication code",
            "Once authenticated, remaining balance is sent automatically"
          ]
        }
      ]
    },
    {
      id: 4,
      title: "Troubleshooting",
      icon: <FaTools className="w-5 h-5 text-lime-400" />,
      content: [
        {
          subtitle: "Transaction Failed",
          items: [
            "Ensure sufficient funds for gas fees",
            "Check recipient's wallet address",
            "Confirm correct network selection"
          ]
        },
        {
          subtitle: "Seed Phrase Issues",
          items: [
            "Double-check spelling and spacing",
            "Verify network selection",
            "Contact support if issues persist"
          ]
        }
      ]
    },
    {
      id: 5,
      title: "Security and Privacy",
      icon: <FaLock className="w-5 h-5 text-lime-400" />,
      content: [
        {
          subtitle: "Protecting Your Seed Phrase",
          items: [
            "Store in secure, offline location",
            "Avoid digital storage",
            "Use fireproof and waterproof storage"
          ]
        },
        {
          subtitle: "Avoiding Scams",
          items: [
            "Verify wallet addresses carefully",
            "Be cautious of phishing attempts",
            "Never share your seed phrase"
          ]
        }
      ]
    },
    {
      id: 6,
      title: "Advanced Features",
      icon: <FaCogs className="w-5 h-5 text-lime-400" />,
      content: [
        {
          subtitle: "Using the DEX",
          steps: [
            "Navigate to DEX Integration",
            "Select tokens to trade",
            "Review rates and approve",
            "Complete the swap"
          ]
        },
        {
          subtitle: "Divisible Signature Technology",
          features: [
            "Flexible multi-signature approvals",
            "Customizable signing authority",
            "Enhanced security options"
          ]
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-t from-neutral-950 to-zinc-900">
      {/* Hero Section */}
      <section className="w-full px-4 pt-16 pb-12 relative overflow-hidden">
        {/* Background gradient circles */}
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 right-1/4 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000"></div>
        
        <div className="max-w-5xl mx-auto text-white relative">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">
              Knowledge <span className="text-lime-400">Base</span>
            </h1>
            <p className="text-lg text-gray-300 max-w-2xl mx-auto">
              Your comprehensive guide to understanding and using Trust Vault effectively.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full px-4 py-8 relative">
        <div className="max-w-4xl mx-auto">
          {sections.map((section) => (
            <div
              key={section.id}
              className="mb-4 backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-4 transition-all duration-300 hover:border-white/20"
            >
              <button
                className="w-full text-left"
                onClick={() => setExpandedSection(expandedSection === section.id ? null : section.id)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    {section.icon}
                    <h2 className="text-xl font-bold text-white">{section.title}</h2>
                  </div>
                  <BiPlus
                    className={`w-5 h-5 text-lime-400 transform transition-transform duration-300 ${
                      expandedSection === section.id ? 'rotate-45' : ''
                    }`}
                  />
                </div>
              </button>

              <div
                className={`overflow-hidden transition-all duration-500 ${
                  expandedSection === section.id ? 'max-h-[2000px] opacity-100 mt-4' : 'max-h-0 opacity-0'
                }`}
              >
                {section.content.map((item, index) => (
                  <div key={index} className="mb-6 last:mb-0 pl-8">
                    <h3 className="text-lg font-bold text-lime-400 mb-3">{item.subtitle}</h3>
                    
                    {item.text && (
                      <p className="text-gray-300 mb-3 text-sm leading-relaxed">{item.text}</p>
                    )}
                    
                    {item.features && (
                      <ul className="space-y-2">
                        {item.features.map((feature, idx) => (
                          <li key={idx} className="flex items-start space-x-2 text-sm text-gray-300">
                            <FaCheckCircle className="w-4 h-4 text-lime-400 mt-0.5 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                    
                    {item.steps && (
                      <div className="space-y-3">
                        {item.steps.map((step, idx) => (
                          <div key={idx} className="flex items-start space-x-3">
                            <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                              <span className="text-black font-bold text-xs">{idx + 1}</span>
                            </div>
                            <p className="text-sm text-gray-300">{step}</p>
                          </div>
                        ))}
                      </div>
                    )}
                    
                    {item.items && (
                      <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        {item.items.map((listItem, idx) => (
                          <li key={idx} className="flex items-center space-x-2 text-sm text-gray-300">
                            <FaCheckCircle className="w-4 h-4 text-lime-400 flex-shrink-0" />
                            <span>{listItem}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Support Section */}
      <section className="w-full px-4 py-12 bg-zinc-900/50">
        <div className="max-w-4xl mx-auto text-white text-center">
          <h2 className="text-2xl font-bold mb-4 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Need More Help?
          </h2>
          <p className="text-base text-gray-300 mb-6">
            Our support team is available 24/7 to assist you with any questions or concerns.
          </p>
          <div className="grid md:grid-cols-1 gap-4">
            <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-4 hover:border-white/20 transition-all duration-300">
              <FaEnvelope className="w-5 h-5 text-lime-400 mx-auto mb-2" />
              <h3 className="text-lg font-bold mb-2 text-lime-400">Email Support</h3>
              <p className="text-sm text-gray-300">support@trustvault.app</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default KnowledgeBase;
