import nextLevelImage from "./images/main-mobiles.svg";
import trustLogo from "./images/trustvault.svg";
import feature1 from "./images/feature1.svg";
import feature2 from "./images/feature2.svg";
import feature3 from "./images/feature3.svg";
import whatis from "./images/what-is.svg";
import crypto1 from "./images/crypto1.svg";
import crypto2 from "./images/crypto2.svg";
import crypto3 from "./images/crypto3.svg";
import crypto4 from "./images/crypto4.svg";
import crypto5 from "./images/crypto5.svg";
import crypto6 from "./images/crypto6.svg";
import whychoose1 from "./images/why-choose1.svg";
import whychoose2 from "./images/why-choose2.svg";
import download from "./images/new-download.png";
import downloadApp from "./images/new-download-app.png";
import blockchain1 from "./images/blockchain1.svg";
import blockchain2 from "./images/blockchain2.svg";
import blockchain3 from "./images/blockchain3.svg";
import blockchain4 from "./images/blockchain4.svg";
import benefit1 from "./images/benefit1.svg";
import benefit2 from "./images/benefit2.svg";
import benefit3 from "./images/benefit3.svg";
import buttonBlur from "./images/buttonBlur.svg";
import googlePlay from "./images/googlePlay.svg";
import apple from "./images/apple.svg";
import zigBg from "./images/zig-bg.svg";
import linearLine from "./images/linear-line.svg";
import benefitShadow from "./images/benefit-shadow.svg";
import oneVector from "./images/one-vector.svg";
import twoVector from "./images/two-vector.svg";
import exit from "./images/exit.svg";
import plus from "./images/plus.svg";
import whatsappBox from "./images/whatsapp-box.png";
import emailBox from "./images/email-box.png";
import linkedinBox from "./images/linkedin-box.png";
import telegramBox from "./images/tel-box.png";
import Footer from "./Footer";
import FAQ from "./FAQ";
import stonfiLogo from "./images/ston.png";

import React from 'react';
import { motion } from 'framer-motion';
import { FaShieldAlt, FaUserLock, FaExchangeAlt, FaChartLine } from 'react-icons/fa';
import Marquee from 'react-fast-marquee';

// Crypto logos from cryptologos.cc
const bitcoinLogo = "https://cryptologos.cc/logos/bitcoin-btc-logo.png";
const ethereumLogo = "https://cryptologos.cc/logos/ethereum-eth-logo.png";
const tetherLogo = "https://cryptologos.cc/logos/tether-usdt-logo.png";
const usdcLogo = "https://cryptologos.cc/logos/usd-coin-usdc-logo.png";
const xrpLogo = "https://cryptologos.cc/logos/xrp-xrp-logo.png";
const cardanoLogo = "https://cryptologos.cc/logos/cardano-ada-logo.png";
const bnbLogo = "https://cryptologos.cc/logos/bnb-bnb-logo.png";
const chainlinkLogo = "https://cryptologos.cc/logos/chainlink-link-logo.png";
const dogeLogo = "https://cryptologos.cc/logos/dogecoin-doge-logo.png";
const maticLogo = "https://cryptologos.cc/logos/polygon-matic-logo.png";
const shibLogo = "https://cryptologos.cc/logos/shiba-inu-shib-logo.png";
const litecoinLogo = "https://cryptologos.cc/logos/litecoin-ltc-logo.png";
const avalancheLogo = "https://cryptologos.cc/logos/avalanche-avax-logo.png";
const tronLogo = "https://cryptologos.cc/logos/tron-trx-logo.png";
const dotLogo = "https://cryptologos.cc/logos/polkadot-new-dot-logo.png";
const solanaLogo = "https://cryptologos.cc/logos/solana-sol-logo.png";
const nearLogo = "https://cryptologos.cc/logos/near-protocol-near-logo.png";
const algoLogo = "https://cryptologos.cc/logos/algorand-algo-logo.png";
const eosLogo = "https://cryptologos.cc/logos/eos-eos-logo.png";
const kusamaLogo = "https://cryptologos.cc/logos/kusama-ksm-logo.png";
const polkadotLogo = "https://cryptologos.cc/logos/polkadot-new-dot-logo.png";
const uniswapLogo = "https://cryptologos.cc/logos/uniswap-uni-logo.png";

// Arrays for different sections
const cryptoLogos = [
  bitcoinLogo, ethereumLogo, tetherLogo, usdcLogo, xrpLogo, cardanoLogo,
  bnbLogo, chainlinkLogo, dogeLogo, maticLogo, shibLogo, solanaLogo,
  litecoinLogo, avalancheLogo, tronLogo, dotLogo, nearLogo, algoLogo
];

const tokenLogos = [
  bnbLogo, ethereumLogo, solanaLogo,
  tronLogo, algoLogo, eosLogo
];

const stakingLogos = [
  tronLogo, solanaLogo, avalancheLogo,
  bnbLogo, kusamaLogo, polkadotLogo
];

const Home = () => {
  return (
    /* Hero Section */
    <div className="min-h-screen flex flex-col items-center justify-center bg-next-level bg-contain bg-no-repeat">
      <section className="w-full bg-main-bg bg-main-custom min-h-screen bg-contain bg-no-repeat">
        <section className="w-full mx-auto sm:w-2/4 -mt-15 font-bold text-white py-14 text-center flex flex-col items-center justify-center">
          <div className="main-heading-container relative mb-7">
            <h1 className="text-5xl sm:text-5xl lg:text-8xl font-bold">
              <span className="white-text-shadow">next</span>
              <span className="text-lime-400">level</span>
            </h1>
            <p className="text-neutral-200 text-sm sm:text-lg md:text-xl font-light">
              advanced self custody technology
            </p>
          </div>

          {/* Adjusted Image */}
          <img
            src={nextLevelImage}
            alt="Next Level Technology"
            className="main-mobiles responsive-image"
          />
        </section>

        <section
          className="w-full px-4 text-gray-800 mb-16 bg-full-screen-shadow bg-contain bg-no-repeat"
          style={{ backgroundPosition: "40% 50%", backgroundSize: "40%" }}
        >
          <div className="text-center mb-8 py-4">
            <h1 className="text-4xl font-extrabold white-text-shadow">
              Key Features
            </h1>
          </div>

        <section className="w-full mb-14 px-4">
          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-white">
            {/* First Card */}
            <div
              className="px-6 py-6 border rounded-3xl shadow-md bg-gradient-to-t from-neutral-950 to-zinc-900 flex flex-col justify-between items-start"
              style={{ minHeight: "280px" }}
            >
              <div className="flex-shrink-0">
                <img
                  src={feature1}
                  className="w-13 mx-auto"
                  alt="Multi-Signature Technology"
                />
              </div>
              <div className="flex-1 flex flex-col justify-evenly">
                <div>
                  <h2 className="text-xl font-semibold">
                    Multi-Signature Technology
                  </h2>
                  <h4 className="font-light italic text-base md:text-md">
                    An additional layer of security
                  </h4>
                </div>
                <div>
                  <p className="text-base md:text-md lg:text-md text-stone-300 font-light">
                    Require multiple signatures for transactions, ensuring
                    enhanced protection.
                  </p>
                </div>
              </div>
            </div>

            {/* Second Card */}
            <div
              className="px-6 py-6 bg-middle-feature bg-contain bg-no-repeat border rounded-3xl shadow-md flex flex-col justify-between items-start"
              style={{ minHeight: "280px" }}
            >
              <div className="flex-shrink-0">
                <img
                  src={feature2}
                  className="w-12 mx-auto"
                  alt="Self-Custody"
                />
              </div>
              <div className="flex-1 flex flex-col justify-evenly">
                <div className="mb-3">
                  <h2 className="text-xl font-semibold">Self-Custody</h2>
                  <h4 className="font-light italic text-base md:text-md">
                    Your keys, your crypto
                  </h4>
                </div>
                <div>
                  <p className="text-base md:text-md lg:text-md text-stone-300 font-light">
                    Store your crypto securely, with full control over your
                    private keys.
                  </p>
                </div>
              </div>
            </div>

            {/* Third Card */}
            <div
              className="px-6 py-6 border rounded-3xl shadow-md bg-gradient-to-t from-neutral-950 to-zinc-900 flex flex-col justify-between items-start"
              style={{ minHeight: "280px" }}
            >
              <div className="flex-shrink-0">
                <img
                  src={feature3}
                  className="w-15 mx-auto"
                  alt="Buy, Swap, Send and Store"
                />
              </div>
              <div className="flex-1 flex flex-col justify-evenly">
                <div className="mb-3">
                  <h2 className="text-xl font-semibold">
                    Buy, Swap, Send and Store
                  </h2>
                  <h4 className="font-light italic text-base md:text-md">
                    Easily manage your crypto portfolio
                  </h4>
                </div>
                <div>
                  <p className="text-base md:text-md lg:text-md text-stone-300 font-light">
                    Purchase, exchange, and transfer your cryptocurrencies, all
                    within the app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* What is SafeSend Section */}
        <section className="w-full mt-8 px-4 bg-full-screen-shadow bg-no-repeat text-center" style={{ backgroundPosition: "150% 140%", backgroundSize: "60%" }}>
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
              <div className="lg:w-1/2 text-left">
                <h2 className="text-4xl font-bold text-white mb-6 white-text-shadow">
                  What is SafeSend?
                </h2>
                <p className="text-gray-300 mb-8">
                  SafeSend is a revolutionary multisig wallet that provides enhanced security for your digital assets through multi-signature technology. With SafeSend, multiple parties must approve each transaction, significantly reducing the risk of unauthorized access or theft.
                </p>
                <div className="flex flex-wrap gap-6">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 rounded-full bg-gradient-to-br from-blue-500 to-indigo-600 flex items-center justify-center">
                      <FaShieldAlt className="w-6 h-6 text-white" />
                    </div>
                    <div>
                      <h3 className="text-white font-semibold">Secure</h3>
                      <p className="text-gray-400 text-sm">Multi-layer protection</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 rounded-full bg-gradient-to-br from-purple-500 to-pink-600 flex items-center justify-center">
                      <FaUserLock className="w-6 h-6 text-white" />
                    </div>
                    <div>
                      <h3 className="text-white font-semibold">Private</h3>
                      <p className="text-gray-400 text-sm">Full user control</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2">
                <img 
                  src={whatis}
                  alt="SafeSend Features" 
                  className="w-full max-w-md mx-auto"
                />
              </div>
            </div>
          </div>
        </section>

        {/* How it Works Section */}
        <section
          className="w-full mt-16 mb-12 bg-full-screen-shadow bg-no-repeat"
          style={{ backgroundPosition: "150% 50%", backgroundSize: "60%" }}
        >
          <div className="max-w-6xl mx-auto text-white">
            <div className="text-center mb-10">
              <h2 className="text-center text-4xl font-extrabold mb-8 white-text-shadow">
                How it Works
              </h2>
            </div>
            <div className="flex flex-col lg:flex-row justify-center lg:space-x-8 space-y-6 lg:space-y-0">
              {/* First Card */}
              <div className="first-vector-card flex flex-col items-center w-full lg:w-[45%] px-4 mx-auto">
                <div className="relative mb-6 w-full flex justify-start">
                  <img
                    src={oneVector}
                    className="one-vector absolute -left-12 top-1/2 transform -translate-y-1/2 z-0 hidden lg:block"
                    alt="Secure Setup"
                    style={{ width: "15%" }}
                  />
                  <div className="bg-gray-800 rounded-3xl py-8 px-4 w-full min-h-[200px] relative z-10">
                    <h3 className="text-2xl font-semibold mb-2 sm:text-xl">
                      Secure Setup
                    </h3>
                    <p className="text-gray-400">
                      Create your multisig wallet by selecting the number of
                      signatures required to approve a transaction. Invite
                      trusted parties to join the wallet, each with their own
                      private key securely stored on their device.
                    </p>
                  </div>
                </div>
              </div>

              {/* Second Card */}
              <div className="second-vector-card flex flex-col items-center w-full lg:w-[45%] px-4 mx-auto">
                <div className="relative mb-6 w-full flex justify-start">
                  <img
                    src={twoVector}
                    className="two-vector absolute -left-12 top-1/2 transform -translate-y-1/2 z-0 hidden lg:block"
                    alt="Approve Transactions"
                    style={{ width: "15%" }}
                  />
                  <div className="bg-gray-800 rounded-3xl py-8 px-4 w-full min-h-[200px] relative z-10">
                    <h3 className="text-2xl font-semibold mb-2 sm:text-xl">
                      Approve Transactions
                    </h3>
                    <p className="text-gray-400">
                      When a transaction is initiated, it requires approval from
                      the designated number of signers. Once the required
                      signatures are collected, the transaction is securely
                      executed on the blockchain, ensuring maximum security and
                      control.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Marquee Section */}
        <div className="relative w-full" style={{ margin: "40px 0" }}>
          <div style={{ 
            background: "#101B40",
            transform: "rotate(-2deg) scale(1.1)",
            margin: "0 -5%",
            padding: "12px 0",
            overflow: "hidden"
          }}>
            <Marquee speed={50} gradient={false} direction="right">
              <div className="flex items-center gap-16">
                <div className="flex items-center gap-3">
                  <img src={bitcoinLogo} alt="Bitcoin" className="w-12 h-12" />
                  <span className="text-xl text-white">Bitcoin</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={ethereumLogo} alt="Ethereum" className="w-12 h-12" />
                  <span className="text-xl text-white">Ethereum</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={tetherLogo} alt="Tether" className="w-12 h-12" />
                  <span className="text-xl text-white">Tether</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={usdcLogo} alt="USDC" className="w-12 h-12" />
                  <span className="text-xl text-white">USDC</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={xrpLogo} alt="XRP" className="w-12 h-12" />
                  <span className="text-xl text-white">XRP</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={cardanoLogo} alt="Cardano" className="w-12 h-12" />
                  <span className="text-xl text-white">Cardano</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={bnbLogo} alt="BNB" className="w-12 h-12" />
                  <span className="text-xl text-white">BNB</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={chainlinkLogo} alt="Chainlink" className="w-12 h-12" />
                  <span className="text-xl text-white">Chainlink</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={dogeLogo} alt="Doge" className="w-12 h-12" />
                  <span className="text-xl text-white">Doge</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={maticLogo} alt="Matic" className="w-12 h-12" />
                  <span className="text-xl text-white">Matic</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={shibLogo} alt="Shib" className="w-12 h-12" />
                  <span className="text-xl text-white">Shib</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={litecoinLogo} alt="Litecoin" className="w-12 h-12" />
                  <span className="text-xl text-white">Litecoin</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={avalancheLogo} alt="Avalanche" className="w-12 h-12" />
                  <span className="text-xl text-white">Avalanche</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={tronLogo} alt="Tron" className="w-12 h-12" />
                  <span className="text-xl text-white">Tron</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={dotLogo} alt="Polkadot" className="w-12 h-12" />
                  <span className="text-xl text-white">Polkadot</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={solanaLogo} alt="Solana" className="w-12 h-12" />
                  <span className="text-xl text-white">Solana</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={nearLogo} alt="Near" className="w-12 h-12" />
                  <span className="text-xl text-white">Near</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={algoLogo} alt="Algorand" className="w-12 h-12" />
                  <span className="text-xl text-white">Algorand</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={eosLogo} alt="EOS" className="w-12 h-12" />
                  <span className="text-xl text-white">EOS</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={kusamaLogo} alt="Kusama" className="w-12 h-12" />
                  <span className="text-xl text-white">Kusama</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={polkadotLogo} alt="Polkadot" className="w-12 h-12" />
                  <span className="text-xl text-white">Polkadot</span>
                </div>
                <div className="flex items-center gap-3">
                  <img src={uniswapLogo} alt="Uniswap" className="w-12 h-12" />
                  <span className="text-xl text-white">Uniswap</span>
                </div>
              </div>
            </Marquee>
          </div>
        </div>

        {/* Supported Blockchains Section */}
        <section className="w-full py-16 px-4  text-center">
          <h1 className="text-5xl font-bold text-white mb-4 white-text-shadow">
            Supported Blockchains and Currencies
          </h1>
          <h2 className="text-2xl text-gray-300 mb-8">
            Comprehensive Support for All Your Digital Assets
          </h2>
          <p className="text-gray-400 max-w-4xl mx-auto mb-16">
            Our platform supports a wide range of blockchains, cryptocurrencies, and NFTs across various protocols. Easily manage your digital assets and collectibles, all in one secure and versatile app.
          </p>

          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-[#1a1f2e] rounded-xl p-6 text-left">
              <h3 className="text-xl font-semibold text-white mb-4">
                Supported cryptocurrencies
              </h3>
              <p className="text-gray-400 mb-4">TOP - 30 Coin Market Cap</p>
                {/* {cryptoLogos.map((logo, index) => (
                  <img key={index} src={logo} alt={`Crypto ${index + 1}`} className="w-8 h-8" />
                ))} */}
                <img src={blockchain1} alt="Bitcoin" className="w-56 h-56" />
            </div>

            <div className="bg-[#1a1f2e] rounded-xl p-6 text-left">
              <h3 className="text-xl font-semibold text-white mb-4">
                Supported token standards
              </h3>
              <p className="text-gray-400 mb-4">
                BNB, ERC-20, TRC-10, TRC-20, SPL, ALGO, EOS
              </p>
              <img src={blockchain2} alt="Bitcoin" className="w-56 h-56" />

            </div>

            <div className="bg-[#1a1f2e] rounded-xl p-6 text-left">
              <h3 className="text-xl font-semibold text-white mb-4">
                Supported NFTs Protocols
              </h3>
              <p className="text-gray-400 mb-4">ERC-721, ERC-1155, SPL, JETTONS</p>
              <img src={blockchain3} alt="Bitcoin" className="w-56 h-56" />

            </div>

            <div className="bg-[#1a1f2e] rounded-xl p-6 text-left">
              <h3 className="text-xl font-semibold text-white mb-4">
                Staking Coming Soon
              </h3>
              <p className="text-gray-400 mb-4">TRX, SOL, AVAX, BNB, KSM, DOT</p>
              <img src={blockchain4} alt="Bitcoin" className="w-56 h-56" />

            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section
          className="w-full mt-8 px-4 bg-full-screen-shadow bg-no-repeat text-center"
          style={{ backgroundPosition: "150% 50%", backgroundSize: "60%" }}
        >
          <style jsx>{`
            @media (max-width: 768px) {
              .bg-benefits-bg {
                background-image: none;
              }
              .bg-full-screen-shadow {
                background-position: 150% -7%;
                background-size: 100%;
              }
            }

            @media (min-width: 769px) {
              .bg-benefits-bg {
                background-size: 100%;
              }
            }
          `}</style>

          <div className="mb-8 py-4">
            <h1 className="text-5xl white-text-shadow mb-10 text-center font-extrabold">
              Benefits
            </h1>
          </div>
          <div className="max-w-6xl mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-6 py-4 text-white justify-items-center bg-benefits-bg bg-cover bg-no-repeat">
              {/* Left Card */}
              <div className="flex flex-col items-center text-center min-h-64 h-full px-4">
                <div>
                  <img src={benefit1} alt="Feature 1" className="mb-7" />
                </div>
                <div className="mt-4">
                  <h2 className="text-xl font-semibold mb-2">
                    Enhanced Security
                  </h2>
                  <h4 className="text-base font-light mb-5 w-full sm:w-48 mx-auto">
                    Protect your assets with multisig protection
                  </h4>
                  <p className="text-sm text-stone-300 w-full sm:w-72 mx-auto flex-grow">
                    Mitigate the risk of unauthorized transactions and ensure
                    secure storage.
                  </p>
                </div>
              </div>

              {/* Middle Card with Background Image */}
              <div className="flex flex-col items-center text-center relative min-h-64 h-full px-4">
                <div className="relative">
                  <img src={benefitShadow} className="absolute -top-20" alt="Benefit Shadow" />
                </div>
                <img src={benefit2} alt="Feature 2" className="mb-7 z-10" />
                <div className="mt-4">
                  <h2 className="text-xl font-semibold mb-2 z-10 relative">
                    Collaborative Management
                  </h2>
                  <h4 className="text-base font-light mb-5 z-10 w-full sm:w-48 mx-auto">
                    Manage crypto with others, securely
                  </h4>
                  <p className="text-sm text-stone-300 z-10 w-full sm:w-72 mx-auto flex-grow">
                    Multisig enables shared management of crypto assets, with
                    built-in security.
                  </p>
                </div>
              </div>

              {/* Right Card */}
              <div className="flex flex-col items-center text-center min-h-64 h-full px-4">
                <div>
                  <img src={benefit3} alt="Feature 3" />
                </div>
                <div className="mt-4">
                  <h2 className="text-xl font-semibold mb-2">Flexibility</h2>
                  <h4 className="text-base font-light mb-5 w-full sm:w-48 mx-auto">
                    Customize your multisig settings
                  </h4>
                  <p className="text-sm text-stone-300 w-full sm:w-72 mx-auto flex-grow">
                    Choose the number of signatures required and assign roles for
                    tailored security
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Future of Crypto Management */}
        <section
          className="w-full mt-8 px-4 text-gray-800 bg-full-screen-shadow bg-no-repeat text-center py-16"
          style={{ backgroundPosition: "150% 140%", backgroundSize: "60%" }}
        >
           <h1 className="text-5xl font-bold text-white mb-4 white-text-shadow">
           The Future of Secure Crypto Management
          </h1>
          <h2 className="text-2xl text-gray-300 mb-8">
          Key Features that Empower Your Crypto Journey
          </h2>
          <p className="text-gray-400 max-w-4xl mx-auto mb-16">
          Unveil the cutting-edge features of our multisig wallet, crafted to provide top-notch security and control over your digital assets. Here are the six main features that set our wallet apart in the industry.          </p>
          {/* Grid for Cards */}
          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-white">
            {/* First Card (Odd) */}
            <div
              className="relative px-6 py-10 border rounded-3xl shadow-md mb-6"
              style={{
                backgroundImage: `url(${zigBg}), linear-gradient(to top, #121318, #1D2235)`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                minHeight: "400px",
              }}
            >
              <img src={crypto1} className="w-15 py-4 ml-auto" alt="Feature 1" />
              <h2 className="text-2xl sm:text-xl font-semibold mb-2">
                Personalised dashboard
              </h2>
              <p className="mt-2 text-sm sm:text-xs md:text-sm text-stone-300 font-light">
                Our multisig crypto wallet requires multiple private keys for
                each transaction. In a 2-of-3 setup, two out of three keys must
                approve before the transaction is executed, ensuring strong
                protection for your assets.
              </p>
            </div>

            {/* Second Card (Even) */}
            <div
              className="relative py-10 px-6 border rounded-3xl shadow-md mb-6"
              style={{
                backgroundImage: `url(${zigBg}), linear-gradient(to top, #121318, #1B1E23)`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
              }}
            >
              <img src={crypto2} className="w-15 py-4 ml-auto" alt="Feature 2" />
              <h2 className="text-2xl sm:text-xl font-semibold mb-2">
                Enhanced with Multisig
              </h2>
              <p className="mt-2 text-sm sm:text-xs md:text-sm text-stone-300 font-light">
                Our multisig crypto wallet requires multiple private keys for
                each transaction. In a 2-of-3 setup, two out of three keys must
                approve before the transaction is executed, ensuring strong
                protection for your assets.
              </p>
            </div>

            {/* Third Card (Odd) */}
            <div
              className="relative py-10 px-6 border rounded-3xl shadow-md mb-6"
              style={{
                backgroundImage: `url(${zigBg}), linear-gradient(to top, #121318, #1D2235)`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
              }}
            >
              <img src={crypto3} className="w-15 py-4 ml-auto" alt="Feature 3" />
              <h2 className="text-2xl sm:text-xl font-semibold mb-2">
                Seamless Transactions
              </h2>
              <p className="mt-2 text-sm sm:text-xs md:text-sm text-stone-300 font-light">
                Our multisig crypto wallet requires multiple private keys for
                each transaction. In a 2-of-3 setup, two out of three keys must
                approve before the transaction is executed, ensuring strong
                protection for your assets.
              </p>
            </div>
          </div>
        </section>
        <section className="w-full py-2 mt-6">
          {/* Second Grid for Cards */}
          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-white">
            {/* Fourth Card (Even) */}
            <div
              className="relative px-6 py-10 border rounded-3xl shadow-md mb-6"
              style={{
                backgroundImage: `url(${zigBg}), linear-gradient(to top, #121318, #1B1E23)`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                minHeight: "400px",
              }}
            >
              <img src={crypto4} className="w-15 py-4 ml-auto" alt="Feature 4" />
              <h2 className="text-2xl sm:text-xl font-semibold mb-2">
                Advanced Security
              </h2>
              <p className="mt-2 text-sm sm:text-xs md:text-sm text-stone-300 font-light">
                Our multisig crypto wallet requires multiple private keys for
                each transaction. In a 2-of-3 setup, two out of three keys must
                approve before the transaction is executed, ensuring strong
                protection for your assets.
              </p>
            </div>

            {/* Fifth Card (Odd) */}
            <div
              className="relative py-10 px-6 border rounded-3xl shadow-md mb-6"
              style={{
                backgroundImage: `url(${zigBg}), linear-gradient(to top, #121318, #1D2235)`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
              }}
            >
              <img src={crypto5} className="w-15 py-4 ml-auto" alt="Feature 5" />
              <h2 className="text-2xl sm:text-xl font-semibold mb-2">
                Custom Widgets
              </h2>
              <p className="mt-2 text-sm sm:text-xs md:text-sm text-stone-300 font-light">
                Our multisig crypto wallet requires multiple private keys for
                each transaction. In a 2-of-3 setup, two out of three keys must
                approve before the transaction is executed, ensuring strong
                protection for your assets.
              </p>
            </div>

            {/* Sixth Card (Even) */}
            <div
              className="relative py-10 px-6 border rounded-3xl shadow-md mb-6"
              style={{
                backgroundImage: `url(${zigBg}), linear-gradient(to top, #121318, #1B1E23)`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
              }}
            >
              <img src={crypto6} className="w-15 py-4 ml-auto" alt="Feature 6" />
              <h2 className="text-2xl sm:text-xl font-semibold mb-2">
                Comprehensive Portfolio
              </h2>
              <p className="mt-2 text-sm sm:text-xs md:text-sm text-stone-300 font-light">
                Our multisig crypto wallet requires multiple private keys for
                each transaction. In a 2-of-3 setup, two out of three keys must
                approve before the transaction is executed, ensuring strong
                protection for your assets.
              </p>
            </div>
          </div>
        </section>
      </section>

      {/* Why Choose Section */}
      <section
        className="w-full py-16 px-4 text-gray-800 bg-full-screen-shadow bg-no-repeat"
        style={{ backgroundPosition: "-50% -40%", backgroundSize: "60%" }}
      >
        <section className="w-full px-4 text-gray-800">
          <div className="max-w-5xl mx-auto py-12 px-4 gap-8 text-white rounded-custom bg-gradient-to-t from-zinc-900 to-neutral-900">
            <div className="md:col-span-2 max-w-3xl mx-auto">
              <h2 id="why-choose-heading" className="text-4xl font-semibold">
                Why Choose Our Multisig Wallet?
              </h2>
              <h3 className="font-light text-stone-300 text-lg">
                Unmatched Security and Total Control for Your Digital Assets
              </h3>

              <div className="max-w-6xl mx-auto space-y-16 mt-10">
                {/* First Item */}
                <div className="flex flex-col-reverse md:flex-row items-center md:space-x-8 py-6 rounded-lg">
                  <div className="flex-1 mb-4 mt-4 md:mt-0">
                    <h2 className="text-2xl font-semibold mb-2">
                      Enhanced Security and Peace of Mind
                    </h2>
                    <p className="text-stone-300 mb-3">
                      Our multisig wallet ensures that your digital assets are
                      protected by requiring multiple approvals for every
                      transaction. This reduces the risk of unauthorized access
                      and provides you with the confidence that your funds are
                      secure, even if one key is compromised.
                    </p>

                    <div className="relative">
                      <img
                        src={buttonBlur}
                        className="absolute -bottom-5 left-5"
                        alt="Button Blur"
                      />
                      <button className="relative z-10 bg-gradient-to-t from-neutral-950 to-gray-800 border-2 border-indigo-500 text-white py-3 px-7 rounded-full hover:bg-blue-600 transition mt-2">
                        Learn More
                      </button>
                    </div>
                  </div>
                  <img
                    src={whychoose1}
                    alt="First Image"
                    className="w-full md:w-1/2 rounded-lg mb-4 md:mb-0"
                  />
                </div>

                {/* Second Item (unchanged) */}
                <div className="flex flex-col md:flex-row items-center md:space-x-8 rounded-lg">
                  <img
                    src={whychoose2}
                    alt="Second Image"
                    className="w-full md:w-1/2 rounded-lg"
                  />
                  <div className="flex-1 mt-4">
                    <h2 className="text-2xl font-semibold mb-2">
                      Full Control and Flexibility
                    </h2>
                    <p className="text-stone-300 mb-3">
                      With our wallet, you maintain full control over your
                      assets. The ability to customize the number of required
                      signers gives you the flexibility to tailor security to
                      your specific needs, whether you're managing personal
                      funds, corporate assets, or a joint account.
                    </p>
                    <div className="relative">
                      <img
                        src={buttonBlur}
                        className="absolute -bottom-5 left-5"
                        alt="Button Blur"
                      />
                      <button className="relative z-10 bg-gradient-to-t from-neutral-950 to-gray-800 border-2 border-indigo-500 text-white py-3 px-7 rounded-full hover:bg-blue-600 transition mt-2">
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* Download */}
      <section className="w-full py-16 px-4 bg-full-screen-shadow bg-no-repeat" style={{ backgroundPosition: "150% 140%", backgroundSize: "60%" }}>
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
            <div className="lg:w-1/2">
              <img
                src={downloadApp}
                alt="Download App"
                className="w-full max-w-lg mx-auto"
              />
            </div>
            <div className="lg:w-1/2 text-left">
              <h2 className="text-4xl font-bold text-white mb-6 white-text-shadow">
                Download Our Wallet
              </h2>
              <p className="text-gray-300 mb-8">
                Get started with SafeSend today. Download our secure wallet app and take control of your digital assets with confidence. Available for both iOS and Android devices.
              </p>
              <div className="flex flex-wrap gap-4">
                <a href="#" className="inline-block">
                  <img
                    src={googlePlay}
                    alt="Get it on Google Play"
                    className="h-14 w-auto hover:opacity-80 transition-opacity"
                  />
                </a>
                <a href="#" className="inline-block">
                  <img
                    src={apple}
                    alt="Download on the App Store"
                    className="h-14 w-auto hover:opacity-80 transition-opacity"
                  />
                </a>
              </div>
              <div className="mt-8">
                <div className="flex items-center gap-2 mb-3">
                  <FaShieldAlt className="text-blue-500 w-5 h-5" />
                  <span className="text-gray-300">Secure Storage</span>
                </div>
                <div className="flex items-center gap-2 mb-3">
                  <FaUserLock className="text-purple-500 w-5 h-5" />
                  <span className="text-gray-300">Multi-Signature Support</span>
                </div>
                <div className="flex items-center gap-2">
                  <FaExchangeAlt className="text-pink-500 w-5 h-5" />
                  <span className="text-gray-300">Cross-Chain Compatibility</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <FAQ />
    </section>

    {/* Let's Talk Section */}
    <section className="w-full py-8 px-4 text-center">
      <div className="max-w-7xl mx-auto gap-8 bg-custom-talk bg-center bg-cover bg-no-repeat">
        <div className="relative p-4">
          {/* Border and Inner Content */}
          <div className="py-8 relative z-10">
            <h2 className="text-5xl font-extrabold white-text-shadow">
              Let's Talk
            </h2>
            <p className="text-stone-300 text-xl mt-4 mb-8">
              Begin your journey into the tomorrow of fintech today.
            </p>
          </div>

          <div className="max-w-3xl mx-auto grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 relative">
            {/* Card 1 */}
            <div className="flex justify-center items-center transform hover:scale-105 transition-transform duration-300">
              <img
                src={whatsappBox}
                alt="WhatsApp"
                className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40"
              />
            </div>

            {/* Card 2 */}
            <div className="flex justify-center items-center transform hover:scale-105 transition-transform duration-300">
              <img
                src={telegramBox}
                alt="Telegram"
                className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40"
              />
            </div>

            {/* Card 3 */}
            <div className="flex justify-center items-center transform hover:scale-105 transition-transform duration-300">
              <img
                src={linkedinBox}
                alt="LinkedIn"
                className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40"
              />
            </div>

            {/* Card 4 */}
            <div className="flex justify-center items-center transform hover:scale-105 transition-transform duration-300">
              <img
                src={emailBox}
                alt="Email"
                className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
  );
};

export default Home;
