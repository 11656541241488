import React from "react";
import whychoose1 from "./images/why-choose1.svg";
import whychoose2 from "./images/why-choose2.svg";
import benefit1 from "./images/benefit1.svg";
import benefit2 from "./images/benefit2.svg";
import feature3 from "./images/feature3.svg";
import zigBg from "./images/zig-bg.svg";
import linearLine from "./images/linear-line.svg";
import crypto3 from "./images/crypto3.svg";
import Footer from "./Footer";

const About = () => {
  return (
    <div className="min-h-screen bg-gradient-to-t from-neutral-950 to-zinc-900">
      {/* Hero Section */}
      <section className="w-full px-4 pt-20 pb-16">
        <div className="max-w-6xl mx-auto text-white">
          <div className="text-center mb-16">
            <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold mb-6">
              About <span className="text-lime-400">TrustVault</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Pioneering the next generation of digital asset security with advanced
              multi-signature technology.
            </p>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="w-full px-4 py-16 relative overflow-hidden">
        <img
          src={zigBg}
          alt="Background Pattern"
          className="absolute top-0 right-0 opacity-10 w-full h-full object-cover"
        />
        <div className="max-w-6xl mx-auto text-white relative">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-6">Our Mission</h2>
              <img src={linearLine} alt="Divider" className="mb-6 w-24" />
              <p className="text-gray-300 text-lg leading-relaxed">
                In the rapidly evolving digital finance landscape, safeguarding assets
                against emerging threats is paramount. TrustVault is committed to
                empowering individuals and organizations with cutting-edge security
                solutions that offer both robust protection and user-friendly
                experiences.
              </p>
            </div>
            <div className="flex justify-center">
              <img
                src={whychoose1}
                alt="Security Illustration"
                className="w-4/5 max-w-md"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="w-full px-4 py-16 bg-zinc-900/50">
        <div className="max-w-6xl mx-auto text-white">
          <h2 className="text-4xl font-bold text-center mb-16">Key Features</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Feature 1 */}
            <div className="bg-zinc-900/50 p-8 rounded-3xl border border-gray-800 backdrop-blur-sm hover:border-gray-700 transition-all duration-300">
              <img src={benefit1} alt="Divisible Signature" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">Divisible Signature Technology</h3>
              <p className="text-gray-300">
                Unlike conventional multisig wallets that require a fixed number of
                signatures, our divisible signature approach allows for more granular
                control over transaction approvals.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="bg-zinc-900/50 p-8 rounded-3xl border border-gray-800 backdrop-blur-sm hover:border-gray-700 transition-all duration-300">
              <img src={benefit2} alt="Enhanced Security" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">Enhanced Security Protocols</h3>
              <p className="text-gray-300">
                By distributing authorization across multiple parties with divisible
                signatures, we significantly reduce the risk of unauthorized access.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="bg-zinc-900/50 p-8 rounded-3xl border border-gray-800 backdrop-blur-sm hover:border-gray-700 transition-all duration-300">
              <img src={crypto3} alt="User Interface" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">User-Friendly Interface</h3>
              <p className="text-gray-300">
                Our platform is designed with simplicity in mind, enabling users to
                manage their digital assets effortlessly while benefiting from
                advanced security features.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-6xl mx-auto text-white">
          <h2 className="text-4xl font-bold text-center mb-16">
            Why Choose <span className="text-lime-400">TrustVault</span>?
          </h2>
          <div className="grid md:grid-cols-2 gap-12">
            {/* Left Column */}
            <div className="space-y-8">
              <div className="flex items-start space-x-4">
                <img src={benefit2} alt="Innovation" className="w-12 h-12 mt-1" />
                <div>
                  <h3 className="text-xl font-bold mb-2">Innovative Security Solutions</h3>
                  <p className="text-gray-300">
                    We leverage the latest advancements in cryptographic research to
                    provide state-of-the-art security for your digital assets.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <img src={feature3} alt="Customization" className="w-12 h-12 mt-1" />
                <div>
                  <h3 className="text-xl font-bold mb-2">Tailored for Diverse Needs</h3>
                  <p className="text-gray-300">
                    Whether you're an individual investor or part of an organization,
                    our divisible signature technology offers customizable security
                    settings.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <img src={benefit1} alt="Support" className="w-12 h-12 mt-1" />
                <div>
                  <h3 className="text-xl font-bold mb-2">Dedicated Support</h3>
                  <p className="text-gray-300">
                    Our team of experts is available to assist you with any questions
                    or concerns, ensuring a seamless experience in managing your
                    digital assets.
                  </p>
                </div>
              </div>
            </div>
            {/* Right Column - Call to Action */}
            <div className="bg-zinc-900/50 p-8 rounded-3xl border border-gray-800 backdrop-blur-sm">
              <h3 className="text-2xl font-bold mb-4">Join TrustVault Today</h3>
              <p className="text-gray-300 mb-6">
                Experience the future of digital asset security with our advanced
                divisible signature technology. Protect your investments with
                confidence, knowing that your assets are secured by the most
                innovative solutions available in the industry.
              </p>
              <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-8 rounded-xl transition-all duration-200">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
