import React from "react";
import whychoose1 from "./images/why-choose1.svg";
import whychoose2 from "./images/why-choose2.svg";
import buttonBlur from "./images/buttonBlur.svg";
import googlePlay from "./images/googlePlay.svg";
import apple from "./images/apple.svg";
import downloadAppShadow from "./images/download-app-shadow.svg";
import downloadApp from "./images/download-app.svg";
import blockchain1 from "./images/blockchain1.svg";
import blockchain2 from "./images/blockchain2.svg";
import blockchain3 from "./images/blockchain3.svg";
import blockchain4 from "./images/blockchain4.svg";
import exit from "./images/exit.svg";
import plus from "./images/plus.svg";
import correct from "./images/correct.svg";
import redLine from "./images/red-line.svg";
import greenLine from "./images/green-line.svg";
import redExit from "./images/red-exit.svg";
import whatsappBox from "./images/whatsapp-box.png";
import emailBox from "./images/email-box.png";
import linkedinBox from "./images/linkedin-box.png";
import telegramBox from "./images/tel-box.png";
import email from "./images/email.svg";
import edge from "./images/edge.png";
import mac from "./images/mac.png";
import chrome from "./images/chrome.png";
import brave from "./images/brave.png";
import ios from "./images/ios.png";
import android from "./images/android.png";
import trustLogo from "./images/trustvault.svg";
import footerTel from "./images/footerTel.svg";
import footerTwitter from "./images/footerTwitter.svg";
import footerLinkedIn from "./images/footerLinkedIn.svg";
import footerMedium from "./images/footerMedium.svg";
import footerFacebook from "./images/footerFacebook.svg";
import footerGithub from "./images/footerGithub.svg";
import introSafeSend from "./images/intro.svg";
import Footer from "./Footer";
import FAQ from "./FAQ";

const SafeSend = () => {
  return (
    <section className="w-full px-4">
      <section className="w-full px-4">
        <div className="max-w-6xl mx-auto px-12  gap-8 text-white rounded-custom">
          <div className="flex flex-col md:flex-row items-center md:space-x-8 py-6 rounded-lg">
            <div className="flex-1">
              <h2 className="text-5xl font-bold mb-2 w-1/2">
                Introducing <span className="text-lime-400">SafeSend</span>{" "}
                <span className="text-3xl">by trustvault</span>
              </h2>
              <h3 className="text-xl font-bold mb-4 mt-4">
                Secure and Stress-Free Crypto Transfers
              </h3>
              <p className="text-stone-300 mb-3">
                Say goodbye to transaction anxiety and hello to seamless, secure
                transfers with SafeSend, the innovative feature that protects
                your crypto assets.{" "}
              </p>
            </div>
            <img
              src={introSafeSend}
              alt="First Image"
              className="w-full md:w-1/2 rounded-lg"
            />
          </div>
        </div>
      </section>

      <div className="max-w-6xl mx-auto  gap-8 text-white rounded-custom">
        <div className="md:col-span-2 max-w-5xl mx-auto">
          <h2 className="text-4xl font-extrabold">
            Why Choose Our Multisig Wallet?
          </h2>
          <h3 className="font-light text-stone-300 text-lg">
            Unmatched Security and Total Control for Your Digital Assets
          </h3>

          <div className="max-w-6xl mx-auto space-y-16 mt-10">
            <div className="flex flex-col md:flex-row items-start md:space-x-8 py-6 rounded-lg">
              <div className="flex-1">
                <h2 className="text-2xl font-bold mb-4">How SafeSend Works</h2>
                <div className="flex items-center gap-2">
                  <div className="rounded-full h-9 w-9 bg-lime-400 py-2 px-2 text-center flex items-center justify-center">
                    <p className="text-black font-bold text-lg">1</p>
                  </div>
                  <h3 className="text-lg font-bold">Initiate Transfer</h3>
                </div>
                <p className="text-stone-300 mb-3 mt-1">
                  Enter the recipient's address and amount.
                </p>
                <div className="flex items-center gap-2">
                  <div className="rounded-full h-9 w-9 bg-lime-400 py-2 px-2 text-center flex items-center justify-center">
                    <p className="text-black font-bold text-lg">1</p>
                  </div>
                  <h3 className="text-lg font-bold">Verification</h3>
                </div>
                <p className="text-stone-300 mb-3 mt-1">
                  SafeSend sends a small test transfer to verify the recipient's
                  address.
                </p>
                <div className="flex items-center gap-2">
                  <div className="rounded-full h-9 w-9 bg-lime-400 py-2 px-2 text-center flex items-center justify-center">
                    <p className="text-black font-bold text-lg">1</p>
                  </div>
                  <h3 className="text-lg font-bold">
                    One-Time Password (OTP):
                  </h3>
                </div>
                <p className="text-stone-300 mb-3 mt-1">
                  The recipient receives an OTP to confirm the transfer.
                </p>
                <div className="flex items-center gap-2">
                  <div className="rounded-full h-9 w-9 bg-lime-400 py-2 px-2 text-center flex items-center justify-center">
                    <p className="text-black font-bold text-lg">1</p>
                  </div>
                  <h3 className="text-lg font-bold">Confirmation</h3>
                </div>
                <p className="text-stone-300 mb-3 mt-1">
                  Once verified, the full amount is released.
                </p>
              </div>

              <img
                src={whychoose1}
                alt="First Image"
                className="w-full md:w-1/2 rounded-lg"
              />
            </div>

            <div className="flex flex-col md:flex-row items-start md:space-x-8 py-6 rounded-lg">
              <img
                src={whychoose2}
                alt="Second Image"
                className="w-full md:w-1/2 rounded-lg"
              />
              <div className="flex-1">
                <h2 className="text-2xl font-bold mt-4 mb-4">
                  Full Control and Flexibility
                </h2>
                <p className="text-stone-300 mb-1">
                  - Eliminates Crypto Clipping: No more lost funds due to
                  incorrect addresses.
                </p>
                <p className="text-stone-300 mb-1">
                  - Eliminates Crypto Clipping: No more lost funds due to
                  incorrect addresses.
                </p>
                <p className="text-stone-300 mb-1">
                  - Eliminates Crypto Clipping: No more lost funds due to
                  incorrect addresses.
                </p>
                <p className="text-stone-300 mb-1">
                  - Eliminates Crypto Clipping: No more lost funds due to
                  incorrect addresses.
                </p>
              </div>
            </div>
          </div>
          <div className="max-w-6xl mx-auto mt-10">
            <div className="flex flex-col md:flex-row items-start md:space-x-8 rounded-lg">
              <div className="flex-1">
                <h2 className="text-2xl font-bold mb-4">
                  Enhanced Security and Peace of Mind
                </h2>
                <p className="text-stone-300 mb-1">
                  - Eliminates Crypto Clipping: No more lost funds due to
                  incorrect addresses.
                </p>
                <p className="text-stone-300 mb-1">
                  - Eliminates Crypto Clipping: No more lost funds due to
                  incorrect addresses.
                </p>
                <p className="text-stone-300 mb-1">
                  - Eliminates Crypto Clipping: No more lost funds due to
                  incorrect addresses.
                </p>{" "}
                <p className="text-stone-300 mb-1">
                  - Eliminates Crypto Clipping: No more lost funds due to
                  incorrect addresses.
                </p>
              </div>
              <img
                src={whychoose1}
                alt="First Image"
                className="w-full md:w-1/2 rounded-lg"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-x-4 mt-8 justify-center relative">
          <button className="relative rounded-3xl px-4 py-2 lg:px-8 lg:py-3 border-2 border-gray-500">
            Download
            {/* <img src={roundedDownload} className='absolute bottom-2 right-2 w-6 h-6' alt="Download" /> */}
          </button>
          <button className="text-white hover:text-gray-300 rounded-3xl px-4 py-2 lg:px-8 lg:py-3 border-2 border-indigo-500">
            Get in touch
          </button>
        </div>
      </div>

      <section className="w-full mt-16 mb-16 ">
        <div className="max-w-7xl flex flex-nowrap small-wrap justify-between mx-auto px-1 gap-8 text-white">
          <div className="container w-full md:w-1/2">
            <div className="w-full flex items-center justify-evenly">
              <img src={redExit} alt="Exit Icon" />
              <h3 className="text-xl font-bold">
                Common Problems in Crypto Payments
              </h3>
              <img src={plus} alt="Plus Icon" />
            </div>

            <div
              className="w-full bg-gray-800 py-6 px-6 border-2 border-white relative mt-4"
              style={{ borderRadius: "36px" }}
            >
              <img
                src={greenLine}
                style={{ position: "absolute", right: "-4px", top: "110px" }}
              />
              <h3 className="text-xl font-bold mt-5">Crypto Clipping</h3>
              <p className="font-normal mt-1">
                Accidental clipping of malware infected addresses when copying
                and pasting can lead to funds being sent to the wrong recipient,
                resulting in a permanent loss of assets.
              </p>
              <h3 className="text-xl font-bold mt-5">
                Incorrect Address Sends
              </h3>
              <p className="font-normal mt-1">
                Sending funds to an incorrect or invalid address can be
                catastrophic, with no way to recover the lost cryptocurrency.
              </p>
              <h3 className="text-xl font-bold mt-5">
                High-Stress Transactions
              </h3>
              <p className="font-normal mt-1">
                Sending large amounts can be nerve-wracking, with the fear of
                making a mistake that could cost thousands or even millions in
                assets.
              </p>
              <h3 className="text-xl font-bold mt-5">Lack of Verification</h3>
              <p className="font-normal mt-1">
                Many wallets don't offer a confirmation step before finalizing
                transactions, increasing the risk of errors and misdirected
                funds.
              </p>
              <h3 className="text-xl font-bold mt-5">Irreversible Errors</h3>
              <p className="font-normal mt-1">
                Once a crypto transaction is sent, it’s irreversible. This
                leaves no room for correcting mistakes or retrieving funds sent
                to the wrong address.
              </p>
            </div>
          </div>

          <div className="container w-full md:w-1/2">
            <div className="w-full flex items-center justify-evenly">
              <img src={redExit} alt="Exit Icon" />
              <h3 className="text-xl font-bold">
                Common Problems in Crypto Payments
              </h3>
              <img src={plus} alt="Plus Icon" />
            </div>

            <div
              className="w-full bg-gray-800 py-6 px-6 border-2 border-stone-300 relative mt-4"
              style={{ borderRadius: "36px" }}
            >
              <img
                src={redLine}
                style={{ position: "absolute", left: "-4px", top: "110px" }}
              />
              <h3 className="text-xl font-bold mt-5">Crypto Clipping</h3>
              <p className="font-normal mt-1">
                Accidental clipping of malware infected addresses when copying
                and pasting can lead to funds being sent to the wrong recipient,
                resulting in a permanent loss of assets.
              </p>
              <h3 className="text-xl font-bold mt-5">
                Incorrect Address Sends
              </h3>
              <p className="font-normal mt-1">
                Sending funds to an incorrect or invalid address can be
                catastrophic, with no way to recover the lost cryptocurrency.
              </p>
              <h3 className="text-xl font-bold mt-5">
                High-Stress Transactions
              </h3>
              <p className="font-normal mt-1">
                Sending large amounts can be nerve-wracking, with the fear of
                making a mistake that could cost thousands or even millions in
                assets.
              </p>
              <h3 className="text-xl font-bold mt-5">Lack of Verification</h3>
              <p className="font-normal mt-1">
                Many wallets don’t offer a confirmation step before finalizing
                transactions, increasing the risk of errors and misdirected
                funds.
              </p>
              <h3 className="text-xl font-bold mt-5">Irreversible Errors</h3>
              <p className="font-normal mt-1">
                Once a crypto transaction is sent, it’s irreversible. This
                leaves no room for correcting mistakes or retrieving funds sent
                to the wrong address.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="text-white mt-8 mb-8 mx-auto w-full">
        <div
          id="main-download"
          className="pt-4 mb-8 mx-auto flex flex-wrap justify-center items-center font-semibold relative w-11/12"
          style={{
            background: "linear-gradient(180deg, #1a1a1a, #0d0d0d)",
            borderRadius: "1rem",
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* Shadow Pseudo-Element with New Gradient Background */}
          <div
            style={{
              content: '""',
              position: "absolute",
              inset: "143px 0px 0px",
              borderRadius: "1rem",
              zIndex: -1,
              background:
                "linear-gradient(rgba(4, 4, 4, 0) 0%, rgba(4, 9, 4, 0.6) 30%, rgb(4, 4, 4) 100%)",
              opacity: 0.5,
            }}
          ></div>

          {/* Main Content */}
          <div className="w-full sm:w-1/2 md:w-[46%] flex flex-col centered-text">
            <h2 className="white-text-shadow text-3xl font-bold">
              Download Our App
            </h2>

            {/* Flex container for buttons */}
            <div className="buttons-container w-full flex justify-center items-center mt-4 gap-x-5 gap-y-4 flex-wrap md:flex-nowrap">
              {/* Google Play Button */}
              <div
                id="google-play-div"
                className="bg-gradient-to-t from-neutral-950 to-gray-800 flex items-center justify-center gap-2 border-2 border-indigo-500 px-3 py-1 rounded-3xl lg:w-[47%] md:w-[47%] w-80"
              >
                <div>
                  <img
                    src={googlePlay}
                    alt="google-play"
                    className="w-5 sm:w-6"
                  />
                </div>
                <div className="text-left">
                  <p className="text-xs font-medium">GET IT ON</p>
                  <h3 className="text-sm sm:text-base font-semibold">
                    Google Play
                  </h3>
                </div>
              </div>

              {/* App Store Button */}
              <div
                id="app-store-div"
                className="bg-gradient-to-t from-neutral-950 to-gray-800 flex items-center justify-center gap-2 border-2 border-indigo-500 px-3 py-1 rounded-3xl lg:w-[47%] md:w-[47%] w-80"
              >
                <div>
                  <img src={apple} alt="apple" className="w-5 sm:w-6" />
                </div>
                <div className="justify-center">
                  <p className="text-xs font-medium text-center">
                    Download on the
                  </p>
                  <h3 className="text-sm sm:text-base font-semibold text-center">
                    App Store
                  </h3>
                </div>
              </div>
            </div>
          </div>

          {/* Image Section with responsive settings */}
          <div className="download-img-container w-full sm:w-2/5 md:w-2/5 lg:w-2/5 text-center md:text-right mt-6">
            <img
              src={downloadApp}
              alt="download-app"
              className="w-3/4 h-auto max-w-xs mx-auto"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
      </section>

      <section
        className="w-full py-16 px-4 bg-full-screen-shadow bg-no-repeat text-center"
        style={{ backgroundPosition: "-60% 60%", backgroundSize: "70%" }}
      >
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-extrabold text-center mb-4 text-white white-text-shadow">
            Supported Blockchains and Currencies
          </h2>
          <h3 className="text-xl font-medium text-center mb-4 text-white">
            Comprehensive Support for All Your Digital Assets
          </h3>
          <p className="font-normal text-stone-300 text-center mb-8">
            Our platform supports a wide range of blockchains, cryptocurrencies,
            and NFTs across various protocols. Easily manage your digital assets
            and collectibles, all in one secure and versatile app.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-10 gap-6 text-center">
            {" "}
            {/* Change to 10 columns */}
            {/* First Card (Larger) */}
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 rounded-xl p-6 flex flex-col justify-between text-white md:col-span-4">
              {" "}
              {/* Spans 4 columns */}
              <div>
                <h3 className="text-xl font-semibold mb-2">
                  Supported cryptocurrencies
                </h3>
                <p className="text-base mb-6">
                  Description of the main feature that is larger and more
                  detailed.
                </p>
              </div>
              <img
                src={blockchain1}
                alt="Main Feature"
                className="w-64 mx-auto"
              />
            </div>
            {/* Other Three Cards */}
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 rounded-xl px-4 py-6 flex flex-col justify-between text-white md:col-span-2">
              {" "}
              {/* Spans 2 columns */}
              <div>
                <h3 className="text-xl font-semibold mb-2">
                  Supported token standards
                </h3>
                <p className="text-base mb-6">
                  Brief description of feature 1.
                </p>
              </div>
              <img src={blockchain2} alt="Feature 1" className="1/3 mx-auto" />
            </div>
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 rounded-xl px-4 py-6 flex flex-col justify-between text-white md:col-span-2">
              {" "}
              {/* Spans 2 columns */}
              <div>
                <h3 className="text-xl font-semibold mb-2">Supported NFTs</h3>
                <p className="text-base mb-6">
                  Brief description of feature 2.
                </p>
              </div>
              <img src={blockchain3} alt="Feature 2" className="1/3 mx-auto" />
            </div>
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 rounded-xl px-4 py-6 flex flex-col justify-between text-white md:col-span-2">
              {" "}
              {/* Spans 2 columns */}
              <div>
                <h3 className="text-xl font-semibold mb-2">
                  Staking Coming Soon
                </h3>
                <p className="text-base mb-6">
                  Brief description of feature 3.
                </p>
              </div>
              <img src={blockchain4} alt="Feature 3" className="1/3 mx-auto" />
            </div>
          </div>
        </div>
      </section>
      {/* FAQ Section */}
      <FAQ />

      <Footer />
    </section>
  );
};

export default SafeSend;
