import React from "react";
import whychoose1 from "./images/why-choose1.svg";
import whychoose2 from "./images/why-choose2.svg";
import benefit1 from "./images/benefit1.svg";
import benefit2 from "./images/benefit2.svg";
import feature3 from "./images/feature3.svg";
import zigBg from "./images/zig-bg.svg";
import linearLine from "./images/linear-line.svg";
import crypto1 from "./images/crypto1.svg";
import crypto2 from "./images/crypto2.svg";
import crypto3 from "./images/crypto3.svg";
import crypto4 from "./images/crypto4.svg";
import crypto5 from "./images/crypto5.svg";
import crypto6 from "./images/crypto6.svg";
import buttonBlur from "./images/buttonBlur.svg";
import Footer from "./Footer";
import toast, { Toaster } from 'react-hot-toast';

const Features = () => {
  const handleCreateWalletClick = () => {
    toast.success('Launching soon!', {
      duration: 5000,
      style: {
        background: '#333',
        color: '#fff',
        padding: '16px',
        fontSize: '1.2rem',
        minWidth: '300px',
      },
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-t from-neutral-950 to-zinc-900">
      <Toaster 
        position="top-center" 
        containerStyle={{
          top: 100,
        }}
      />
      {/* Hero Section */}
      <section className="w-full px-4 pt-20 pb-16">
        <div className="max-w-6xl mx-auto text-white">
          <div className="text-center mb-16">
            <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold mb-6">
              Powerful <span className="text-lime-400">Features</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Experience the future of digital asset management with our comprehensive suite of features
            </p>
          </div>
        </div>
      </section>

      {/* Main Features Grid */}
      <section className="w-full px-4 py-16 relative overflow-hidden">
        <img
          src={zigBg}
          alt="Background Pattern"
          className="absolute top-0 right-0 opacity-10 w-full h-full object-cover"
        />
        <div className="max-w-6xl mx-auto text-white">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Wallet Options */}
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800 hover:border-gray-700 transition-all duration-300">
              <img src={crypto1} alt="Wallet Options" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">Versatile Wallet Options</h3>
              <p className="text-gray-300">
                Choose between intuitive single-signature wallets for simple transactions or advanced 
                multi-signature wallets for enhanced security. Customize your setup based on your needs.
              </p>
            </div>

            {/* SafeSend */}
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800 hover:border-gray-700 transition-all duration-300">
              <img src={crypto2} alt="SafeSend" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">SafeSend Technology</h3>
              <p className="text-gray-300">
                Our revolutionary SafeSend feature protects large transfers with OTP verification and test 
                transactions, ensuring your funds always reach their intended destination.
              </p>
            </div>

            {/* DEX Integration */}
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800 hover:border-gray-700 transition-all duration-300">
              <img src={crypto3} alt="DEX Integration" className="w-16 h-16 mb-6" />
              <h3 className="text-xl font-bold mb-4">Integrated DEX Access</h3>
              <p className="text-gray-300">
                Trade cryptocurrencies directly within the app through our seamless integration with 
                multiple decentralized exchanges. Access vast trading opportunities in one place.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Upcoming Features Section */}
      <section className="w-full px-4 py-16 bg-zinc-900/50">
        <div className="max-w-6xl mx-auto text-white">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-6">Coming Soon</h2>
            <img src={linearLine} alt="Divider" className="mx-auto mb-6 w-24" />
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Exciting new features on the horizon to enhance your crypto experience
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800">
              <div className="flex items-center space-x-4 mb-6">
                <img src={crypto4} alt="Onramp" className="w-12 h-12" />
                <h3 className="text-2xl font-bold">Crypto Onramps</h3>
              </div>
              <p className="text-gray-300 mb-4">
                Seamlessly convert your fiat currency to cryptocurrencies directly within the app. 
                Our upcoming onramp services will make entering the crypto market easier than ever.
              </p>
              <div className="relative mt-6">
                <img src={buttonBlur} className="absolute -bottom-5 left-5" alt="Button Blur" />
                <button className="relative z-10 bg-gradient-to-t from-neutral-950 to-gray-800 border-2 border-indigo-500 text-white py-3 px-7 rounded-full hover:bg-blue-600 transition">
                  Learn More
                </button>
              </div>
            </div>

            <div className="bg-gradient-to-t from-neutral-950 to-zinc-800 p-8 rounded-3xl border border-gray-800">
              <div className="flex items-center space-x-4 mb-6">
                <img src={crypto5} alt="Offramp" className="w-12 h-12" />
                <h3 className="text-2xl font-bold">Crypto Offramps</h3>
              </div>
              <p className="text-gray-300 mb-4">
                Convert your cryptocurrencies back to fiat with ease. Our offramp services will provide 
                a smooth exit pathway when you need to access traditional currencies.
              </p>
              <div className="relative mt-6">
                <img src={buttonBlur} className="absolute -bottom-5 left-5" alt="Button Blur" />
                <button className="relative z-10 bg-gradient-to-t from-neutral-950 to-gray-800 border-2 border-indigo-500 text-white py-3 px-7 rounded-full hover:bg-blue-600 transition">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Additional Features Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-6xl mx-auto text-white">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-6">Additional Features</h2>
              <img src={linearLine} alt="Divider" className="mb-6 w-24" />
              
              <div className="space-y-8">
                <div className="flex items-start space-x-4">
                  <img src={crypto6} alt="Interface" className="w-12 h-12 mt-1" />
                  <div>
                    <h3 className="text-xl font-bold mb-2">User-Friendly Interface</h3>
                    <p className="text-gray-300">
                      Navigate your digital assets with ease using our sleek and intuitive interface, 
                      designed for users of all experience levels.
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <img src={benefit1} alt="Access" className="w-12 h-12 mt-1" />
                  <div>
                    <h3 className="text-xl font-bold mb-2">24/7 Access</h3>
                    <p className="text-gray-300">
                      Enjoy uninterrupted access to your assets around the clock with our reliable 
                      platform.
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <img src={benefit2} alt="Recovery" className="w-12 h-12 mt-1" />
                  <div>
                    <h3 className="text-xl font-bold mb-2">Instant Account Recovery</h3>
                    <p className="text-gray-300">
                      Rest easy knowing your account can be swiftly and securely recovered in case 
                      of device loss or theft.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <img
                src={whychoose1}
                alt="Security Illustration"
                className="w-4/5 max-w-md"
              />
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full px-4 py-16 bg-zinc-900/50">
        <div className="max-w-4xl mx-auto text-center text-white">
          <h2 className="text-4xl font-bold mb-6">Ready to Get Started?</h2>
          <p className="text-xl text-gray-300 mb-8">
            Join TrustVault today and experience the future of digital asset management
          </p>
          <div className="relative">
            <img src={buttonBlur} className="absolute -bottom-5 left-1/2 transform -translate-x-1/2" alt="Button Blur" />
            <button 
              onClick={handleCreateWalletClick}
              className="relative z-10 bg-gradient-to-t from-neutral-950 to-gray-800 border-2 border-indigo-500 text-white py-4 px-8 rounded-full hover:bg-blue-600 transition text-lg font-bold"
            >
              Create Your Wallet
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Features;
