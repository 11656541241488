import React, { useState } from "react";
import Footer from "./Footer";

const FAQPage = () => {
  const [expanded, setExpanded] = useState(null);
  const faqData = [
    {
      id: 1,
      category: "General",
      questions: [
        {
          id: "g1",
          question: "What is TrustVault?",
          answer: "TrustVault is a secure, user-friendly cryptocurrency wallet that combines advanced security features with an intuitive interface. It offers multisig functionality, keychain storage, and various other security measures to protect your digital assets."
        },
        {
          id: "g2",
          question: "Is TrustVault safe to use?",
          answer: "Yes, TrustVault implements multiple layers of security including multisig technology, secure keychain storage, and advanced encryption. We never store your private keys on our servers, and all sensitive operations are performed locally on your device."
        },
        {
          id: "g3",
          question: "What cryptocurrencies does TrustVault support?",
          answer: "TrustVault supports a wide range of cryptocurrencies including Bitcoin, Ethereum, and other major tokens. We regularly update our supported assets based on community demand and security considerations."
        }
      ]
    },
    {
      id: 2,
      category: "Security",
      questions: [
        {
          id: "s1",
          question: "How does multisig enhance my wallet security?",
          answer: "Multisig (multi-signature) requires multiple approvals for transactions, significantly reducing the risk of unauthorized access. Even if one key is compromised, your funds remain secure as long as the other keys are safe."
        },
        {
          id: "s2",
          question: "What happens if I lose my device?",
          answer: "TrustVault includes robust recovery options. With our backup system, you can restore your wallet on a new device using your recovery phrase and additional security measures."
        },
        {
          id: "s3",
          question: "How does SafeSend protect my transactions?",
          answer: "SafeSend analyzes transaction details in real-time, checking for common scams, suspicious addresses, and unusual patterns. It provides warnings and requires additional confirmation for potentially risky transactions."
        }
      ]
    },
    {
      id: 3,
      category: "Features",
      questions: [
        {
          id: "f1",
          question: "What is the SafeSend feature?",
          answer: "SafeSend is our proprietary transaction verification system that helps prevent accidental transfers and protects against common cryptocurrency scams by analyzing transactions before they're sent."
        },
        {
          id: "f2",
          question: "Can I use TrustVault on multiple devices?",
          answer: "Yes, TrustVault supports multi-device access with proper security synchronization. Each device can be configured as part of your multisig setup or as a view-only wallet."
        },
        {
          id: "f3",
          question: "Does TrustVault support hardware wallets?",
          answer: "Yes, TrustVault can integrate with popular hardware wallets for additional security. You can use hardware wallets as part of your multisig setup or for transaction signing."
        }
      ]
    }
  ];

  const toggleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-next-level bg-contain bg-no-repeat">
      <div className="pt-20 pb-12">
        {/* Hero Section */}
        <div className="text-center mb-16 px-4">
          <h1 className="text-6xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent mb-6">
            Frequently Asked Questions
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Find answers to common questions about TrustVault's features, security, and functionality
          </p>
        </div>

        {/* FAQ Content */}
        <div className="max-w-5xl mx-auto px-4">
          {faqData.map((category) => (
            <div key={category.id} className="mb-12">
              <h2 className="text-3xl font-bold text-white mb-8">{category.category}</h2>
              <div className="space-y-4">
                {category.questions.map((item) => (
                  <div
                    key={item.id}
                    className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 transition-all duration-300 hover:border-white/20"
                  >
                    <button
                      className="w-full px-8 py-6 text-left flex justify-between items-center group"
                      onClick={() => toggleExpand(item.id)}
                    >
                      <span className="text-lg font-medium text-white pr-8 group-hover:text-lime-400 transition-colors">
                        {item.question}
                      </span>
                      <span className={`transform transition-transform duration-300 text-lime-400 ${
                        expanded === item.id ? 'rotate-45' : ''
                      }`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </span>
                    </button>
                    <div
                      className={`overflow-hidden transition-all duration-300 ${
                        expanded === item.id ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                      }`}
                    >
                      <div className="px-8 pb-6 text-gray-300 leading-relaxed">
                        {item.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Contact Section */}
        <div className="text-center mt-16 px-4">
          <h2 className="text-2xl font-bold text-white mb-4">Still have questions?</h2>
          <p className="text-gray-400 mb-6">
            Can't find the answer you're looking for? Please contact our support team.
          </p>
          <a
            href="mailto:support@trustvault.app"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-black bg-lime-400 hover:bg-lime-500 transition-colors"
          >
            Contact Support
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQPage;
