import React from "react";
import Footer from "./Footer";
import blockchain1 from "./images/blockchain1.svg";
import blockchain2 from "./images/blockchain2.svg";
import blockchain3 from "./images/blockchain3.svg";
import blockchain4 from "./images/blockchain4.svg";
import zigBg from "./images/zig-bg.svg";
import linearLine from "./images/linear-line.svg";
import crypto1 from "./images/crypto1.svg";
import crypto2 from "./images/crypto2.svg";
import crypto3 from "./images/crypto3.svg";
import buttonBlur from "./images/buttonBlur.svg";

const Blockchain101 = () => {
  return (
    <div className="min-h-screen bg-gradient-to-t from-neutral-950 to-zinc-900">
      {/* Hero Section */}
      <section className="w-full px-4 pt-20 pb-16 relative overflow-hidden">
        {/* Background gradient circles */}
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 right-1/4 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000"></div>
        
        <div className="max-w-6xl mx-auto text-white relative">
          <div className="text-center mb-16">
            <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold mb-6">
              Blockchain & Crypto <span className="text-lime-400">101</span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Your ultimate beginner's guide to understanding blockchain technology and cryptocurrencies.
            </p>
          </div>
        </div>
      </section>

      {/* What is Blockchain Section */}
      <section className="w-full px-4 py-16 relative overflow-hidden">
        <img
          src={zigBg}
          alt="Background Pattern"
          className="absolute top-0 right-0 opacity-10 w-full h-full object-cover"
        />
        <div className="max-w-6xl mx-auto text-white">
          <div className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 p-8 mb-12">
            <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              What is Blockchain?
            </h2>
            <img src={linearLine} alt="Divider" className="mb-6 w-24" />
            <p className="text-gray-300 text-lg leading-relaxed mb-8">
              At its core, a blockchain is a digital ledger of transactions that is distributed across a network of computers. Think of it as a highly secure, shared spreadsheet that records transactions and ensures trust without the need for intermediaries.
            </p>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="p-6 bg-black/20 rounded-xl border border-white/5">
                <h3 className="text-xl font-bold mb-3 text-lime-400">Decentralized</h3>
                <p className="text-gray-300">No single entity has control over the entire network.</p>
              </div>
              <div className="p-6 bg-black/20 rounded-xl border border-white/5">
                <h3 className="text-xl font-bold mb-3 text-lime-400">Immutable</h3>
                <p className="text-gray-300">Once a transaction is recorded, it cannot be altered or deleted.</p>
              </div>
              <div className="p-6 bg-black/20 rounded-xl border border-white/5">
                <h3 className="text-xl font-bold mb-3 text-lime-400">Transparent</h3>
                <p className="text-gray-300">All participants in the network can access the same version of the ledger.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How Does Blockchain Work Section */}
      <section className="w-full px-4 py-16 bg-zinc-900/50">
        <div className="max-w-6xl mx-auto text-white">
          <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            How Does Blockchain Work?
          </h2>
          <div className="grid md:grid-cols-5 gap-6">
            {[
              {
                icon: blockchain1,
                title: "Transaction Initiation",
                description: "A user initiates a transaction, such as sending cryptocurrency."
              },
              {
                icon: blockchain2,
                title: "Validation",
                description: "The transaction is verified by a network of computers using consensus mechanisms."
              },
              {
                icon: blockchain3,
                title: "Block Creation",
                description: "Once validated, the transaction is grouped with others into a block."
              },
              {
                icon: blockchain4,
                title: "Chain Addition",
                description: "The block is added to a chain of previous blocks, forming the blockchain."
              },
              {
                icon: crypto1,
                title: "Secure Record",
                description: "The transaction is securely recorded and visible to all participants."
              }
            ].map((step, index) => (
              <div key={index} className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 p-6 relative group hover:border-white/20 transition-all duration-300">
                <div className="absolute -top-3 -left-3 w-8 h-8 rounded-full bg-lime-400 flex items-center justify-center text-black font-bold">
                  {index + 1}
                </div>
                <img src={step.icon} alt={step.title} className="w-12 h-12 mb-4" />
                <h3 className="text-xl font-bold mb-3">{step.title}</h3>
                <p className="text-gray-300">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Cryptocurrency Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-6xl mx-auto text-white">
          <div className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 p-8">
            <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              What is Cryptocurrency?
            </h2>
            <img src={linearLine} alt="Divider" className="mb-6 w-24" />
            <p className="text-gray-300 text-lg leading-relaxed mb-8">
              Cryptocurrency is a digital or virtual form of money that uses cryptography for security. Unlike traditional currencies issued by governments, cryptocurrencies operate on decentralized networks, primarily blockchain.
            </p>
            <div className="grid md:grid-cols-4 gap-6">
              <div className="p-6 bg-black/20 rounded-xl border border-white/5">
                <h3 className="text-xl font-bold mb-3 text-lime-400">Decentralized</h3>
                <p className="text-gray-300">Operates without a central authority, like a bank.</p>
              </div>
              <div className="p-6 bg-black/20 rounded-xl border border-white/5">
                <h3 className="text-xl font-bold mb-3 text-lime-400">Secure</h3>
                <p className="text-gray-300">Transactions are encrypted, ensuring privacy and safety.</p>
              </div>
              <div className="p-6 bg-black/20 rounded-xl border border-white/5">
                <h3 className="text-xl font-bold mb-3 text-lime-400">Global</h3>
                <p className="text-gray-300">Can be used and accessed anywhere with an internet connection.</p>
              </div>
              <div className="p-6 bg-black/20 rounded-xl border border-white/5">
                <h3 className="text-xl font-bold mb-3 text-lime-400">Limited Supply</h3>
                <p className="text-gray-300">Many cryptocurrencies have a capped supply, making them deflationary.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Popular Cryptocurrencies */}
      <section className="w-full px-4 py-16 bg-zinc-900/50">
        <div className="max-w-6xl mx-auto text-white">
          <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Popular Cryptocurrencies
          </h2>
          <div className="grid md:grid-cols-4 gap-6">
            {[
              {
                name: "Bitcoin (BTC)",
                description: "The first and most well-known cryptocurrency, often referred to as 'digital gold.'"
              },
              {
                name: "Ethereum (ETH)",
                description: "A blockchain platform that enables smart contracts and decentralized applications."
              },
              {
                name: "Tether (USDT)",
                description: "A stablecoin pegged to the value of traditional currencies like the US Dollar."
              },
              {
                name: "TON",
                description: "A fast, scalable blockchain designed for mainstream adoption."
              }
            ].map((crypto, index) => (
              <div key={index} className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 p-6 hover:border-white/20 transition-all duration-300">
                <h3 className="text-xl font-bold mb-3 text-lime-400">{crypto.name}</h3>
                <p className="text-gray-300">{crypto.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Getting Started Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-6xl mx-auto text-white">
          <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            How to Get Started
          </h2>
          <div className="grid md:grid-cols-4 gap-6">
            {[
              {
                icon: crypto1,
                title: "Choose a Wallet",
                description: "Select a secure crypto wallet like Trust Vault to store your digital assets."
              },
              {
                icon: crypto2,
                title: "Purchase Crypto",
                description: "Use exchanges or peer-to-peer platforms to buy cryptocurrencies."
              },
              {
                icon: crypto3,
                title: "Learn & Explore",
                description: "Start with small investments and familiarize yourself with blockchain ecosystems."
              },
              {
                icon: blockchain4,
                title: "Stay Secure",
                description: "Use 2FA, avoid sharing private keys, and verify addresses before sending funds."
              }
            ].map((step, index) => (
              <div key={index} className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 p-6 relative group hover:border-white/20 transition-all duration-300">
                <img src={step.icon} alt={step.title} className="w-12 h-12 mb-4" />
                <h3 className="text-xl font-bold mb-3">{step.title}</h3>
                <p className="text-gray-300">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Applications Section */}
      <section className="w-full px-4 py-16 bg-zinc-900/50">
        <div className="max-w-6xl mx-auto text-white">
          <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Blockchain Applications Beyond Cryptocurrency
          </h2>
          <div className="grid md:grid-cols-4 gap-6">
            {[
              {
                title: "Supply Chain",
                description: "Ensures transparency and traceability of goods."
              },
              {
                title: "Healthcare",
                description: "Protects patient data and enables secure data sharing."
              },
              {
                title: "Gaming",
                description: "Powers in-game assets and virtual economies."
              },
              {
                title: "Real Estate",
                description: "Facilitates tokenized property ownership and secure transactions."
              }
            ].map((app, index) => (
              <div key={index} className="backdrop-blur-lg bg-white/5 rounded-2xl border border-white/10 p-6 hover:border-white/20 transition-all duration-300">
                <h3 className="text-xl font-bold mb-3 text-lime-400">{app.title}</h3>
                <p className="text-gray-300">{app.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Stay Informed Section */}
      <section className="w-full px-4 py-16">
        <div className="max-w-4xl mx-auto text-white text-center">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Stay Informed
          </h2>
          <p className="text-xl text-gray-300 mb-8">
            The blockchain and cryptocurrency space is constantly evolving. Keep learning, stay updated with the latest trends, and explore innovative projects shaping the future of this revolutionary technology.
          </p>
          <p className="text-lg text-lime-400">
            Let's build the future together!
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Blockchain101;
