import React from "react";
import Footer from "./Footer";
import { 
  FaFileContract, 
  FaShieldAlt, 
  FaUserCheck, 
  FaWallet, 
  FaBan, 
  FaMoneyBillWave,
  FaExchangeAlt,
  FaCopyright,
  FaGavel,
  FaEnvelope
} from "react-icons/fa";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gradient-to-t from-neutral-950 to-zinc-900">
      {/* Hero Section */}
      <section className="w-full px-4 pt-16 pb-12 relative overflow-hidden">
        {/* Background gradient circles */}
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 right-1/4 w-96 h-96 bg-yellow-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000"></div>
        
        <div className="max-w-4xl mx-auto text-white relative">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">
              Terms and <span className="text-lime-400">Conditions</span>
            </h1>
            <p className="text-lg text-gray-300">
              Last Updated: December 12, 2024
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full px-4 py-8">
        <div className="max-w-4xl mx-auto space-y-8">
          {/* Company Info Card */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-4">
              <FaFileContract className="w-6 h-6 text-lime-400" />
              <div>
                <h2 className="text-xl font-bold">Trust Vault LLC</h2>
                <p className="text-gray-300">Incorporated in Wyoming, United States</p>
              </div>
            </div>
            <p className="text-gray-300 leading-relaxed">
              Welcome to Trust Vault, operated by Trust Vault LLC ("we," "us," "our"). 
              By accessing or using the Trust Vault application ("App"), you agree to comply with these Terms and Conditions. 
              Please read them carefully.
            </p>
          </div>

          {/* Acceptance of Terms */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaUserCheck className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">Acceptance of Terms</h2>
            </div>
            <p className="text-gray-300 leading-relaxed">
              By using Trust Vault, you agree to be bound by these Terms and Conditions and any additional terms that may apply. 
              If you do not agree, please discontinue use of the App.
            </p>
          </div>

          {/* Self-Custody */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaWallet className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">Self-Custody of Assets</h2>
            </div>
            <div className="space-y-4 text-gray-300">
              <p className="leading-relaxed">
                Trust Vault enables self-custody of crypto assets, meaning you have full control and responsibility over your private keys and funds. 
                We do not hold or have access to your assets.
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>You are solely responsible for safeguarding your account credentials</li>
                <li>We cannot retrieve lost or stolen keys</li>
                <li>Take appropriate measures to secure your account</li>
              </ul>
            </div>
          </div>

          {/* Prohibited Activities */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaBan className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">Prohibited Activities</h2>
            </div>
            <ul className="space-y-4 text-gray-300">
              <li className="flex items-start gap-3">
                <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-black font-bold text-xs">1</span>
                </div>
                <p>Use the App for illegal, harmful, or fraudulent activities</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-black font-bold text-xs">2</span>
                </div>
                <p>Distribute malware or harmful code through the App</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-black font-bold text-xs">3</span>
                </div>
                <p>Attempt to access unauthorized accounts</p>
              </li>
              <li className="flex items-start gap-3">
                <div className="w-5 h-5 rounded-full bg-lime-400 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-black font-bold text-xs">4</span>
                </div>
                <p>Violate any applicable laws or regulations</p>
              </li>
            </ul>
          </div>

          {/* Fees and Third-Party Services */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaMoneyBillWave className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">Fees and Third-Party Services</h2>
            </div>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-semibold text-lime-400 mb-3">Fees</h3>
                <p className="text-gray-300 leading-relaxed">
                  While Trust Vault does not directly charge fees, you are responsible for any fees from third-party DEX transactions 
                  or gas fees. These fees are subject to the terms of the respective third-party providers.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-lime-400 mb-3">Third-Party Services</h3>
                <p className="text-gray-300 leading-relaxed">
                  When using integrated third-party services, you are subject to their terms and conditions. 
                  Trust Vault LLC is not liable for any issues arising from your use of third-party services.
                </p>
              </div>
            </div>
          </div>

          {/* Contact Section */}
          <div className="backdrop-blur-lg bg-white/5 rounded-xl border border-white/10 p-6 text-white">
            <div className="flex items-center gap-4 mb-6">
              <FaEnvelope className="w-6 h-6 text-lime-400" />
              <h2 className="text-2xl font-bold">Contact Us</h2>
            </div>
            <p className="text-gray-300 mb-4">
              If you have any questions about these Terms and Conditions, please contact us at:
            </p>
            <p className="text-lime-400">legal@trustvault.app</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
